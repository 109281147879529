import { Button, Form, Offcanvas } from "react-bootstrap";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  editSessionAction,
  createSessionAction,
  fetchSessionAction,
  fetchUnitsAction,
  fetchSessionFromPackAction,
} from "actions/sessions";

import { useLocation } from "react-router-dom";
import { AutocompleteTeachers } from "./AutoCompile";

import { fetchBookAction } from "actions/books";
import { Typeahead } from "react-bootstrap-typeahead";
const AddNewSession = ({
  sessionId,
  setSessionId,
  showSession,
  setShowSession,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const book_id = searchParams.get("book_id");
  const pack_id = searchParams.get("pack_id");
  const { loading } = useSelector((state) => state.auth);
  const { book } = useSelector((state) => state.books);
  const { session: sessionEdited, units } = useSelector(
    (state) => state.sessions
  );

  const { sessions } = useSelector((state) => state.sessions);
  const dispatch = useDispatch();
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  useEffect(() => {
    dispatch(fetchBookAction(book_id));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSessionFromPackAction(book_id));
  }, [dispatch, book_id]);
  const [session, setSession] = useState({
    name: book?.title + " S_" + (sessions?.length + 1),
    book_id: book_id,
    teacher_id: null,
    capacity: 20,
    location: "classroom",
    active: true,
    start_date: "",
    end_date: "",
    description: "",
    pack_id: pack_id,
    unit: "",
  });

  const handleSelection = (selected) => {
    if (selected.length > 0) {
      setSelectedUnit(selected[0]);
      setSession({ ...session, unit: selected[0].name });
    }
  };
  useEffect(() => {
    setSession({ ...session, teacher_id: selectedTeacher[0]?.id });
  }, [selectedTeacher[0]?.id]);
  useEffect(() => {
    setSession({
      ...session,
      name: book?.title + " S_" + (sessions?.length + 1),
    });
  }, [sessions]);
  //   useEffect(() => {
  //     setSession({ ...session, unit: "" });
  //   }, [units]);

  useEffect(() => {
    dispatch(fetchUnitsAction(book_id));
  }, [dispatch]);
  const handleAddSession = async (e) => {
    const leave = 0;
    if (sessionId) {
      dispatch(
        editSessionAction(
          { ...session, id: sessionId, book_id: book_id, pack_id: pack_id },
          handleCloseLeave,
          setSessionId
        )
      );
    } else {
      dispatch(
        createSessionAction(
          { ...session, book_id: book_id, pack_id: pack_id },
          handleClose,
          handleCloseLeave,
          leave
        )
      );
    }
    e.preventDefault();
  };
  const handleAddSessionLeave = async (e) => {
    const leave = 1;
    if (sessionId) {
      dispatch(
        editSessionAction(
          { ...session, id: sessionId, book_id: book_id, pack_id: pack_id },
          handleCloseLeave,
          setSessionId
        )
      );
    } else {
      dispatch(
        createSessionAction(
          { ...session, book_id: book_id, pack_id: pack_id },
          handleClose,
          handleCloseLeave,
          leave
        )
      );
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (sessionId) {
      dispatch(fetchSessionAction(sessionId));
    }
  }, [dispatch, sessionId]);
  useEffect(() => {
    if (sessionEdited.name) {
      setSession({
        name: sessionEdited.name,
        book_id: sessionEdited.book_id,
        teacher_id: sessionEdited.teacher_id,
        location: sessionEdited.location,
        active: sessionEdited.active,
        start_date: sessionEdited.start_date,
        end_date: sessionEdited.end_date,
        description: sessionEdited.description,
        pack_id: sessionEdited.pack_id,
        capacity: sessionEdited.capacity,
      });
    }
  }, [sessionEdited]);
  const handleClose = () => {
    setShowSession(false);
    setSessionId(null);
    setSession({
      name: book?.title + " S_" + (sessions?.length + 1),
      book_id: book_id,
      teacher_id: selectedTeacher[0]?.id,
      capacity: 20,
      location: "classroom",
      active: true,
      start_date: "",
      end_date: "",
      pack_id: pack_id,
      unit: "",
      description: "",
    });
  };
  const handleCloseLeave = () => {
    setShowSession(false);
    setSessionId(null);
    setSession({
      name: "",
      book_id: book_id,
      teacher_id: null,
      capacity: 20,
      location: "classroom",
      active: true,
      start_date: "",
      end_date: "",
      description: "",
      pack_id: pack_id,
      unit: "",
    });
  };
  const handleShow = () => setShowSession(true);
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const newEndDate = addTwoHours(newStartDate);
    setSession({ ...session, start_date: newStartDate, end_date: newEndDate });
  };

  const addTwoHours = (start_date) => {
    const startDateObj = new Date(start_date);
    const newEndDateObj = new Date(startDateObj.getTime() + 3 * 60 * 60 * 1000); // Add 2 hours
    const newEndDate = newEndDateObj.toISOString().slice(0, 16); // Format as "YYYY-MM-DDTHH:mm"

    return newEndDate;
  };
  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add New Session
      </Button>
      {showSession && (
        // <Offcanvas
        // 	show={showSession}
        // 	onHide={handleClose}
        // 	placement="center"
        // 	style={{
        // 		marginLeft:"50%",
        // 		transform: "translateX(-50%)",
        // 		width: "80%",
        // 		heigth:"60%",
        // 		marginTop:"100px",
        // 		marginBottom:"3%",
        // 	}}
        // >
        <Offcanvas
          show={showSession}
          onHide={handleClose}
          style={{
            width: "65%",
            marginLeft: "25%",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        >
          <Offcanvas.Header closeButton style={{ marginBottom: "-30px" }}>
            <Offcanvas.Title>
              {sessionId ? "Update Session" : "Add New Session"}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ height: "100%", overflowY: "auto" }}>
            <Form>
              {/* Name  */}
              <Form.Group className="mb-2">
                <div className="row">
                  <div className="col-md-2" style={{ marginTop: "2px" }}>
                    <Form.Label>Session Name</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Control
                      type="text"
                      placeholder="Session Name"
                      id="name"
                      value={session.name}
                      onChange={(e) =>
                        setSession({ ...session, name: e.target.value })
                      }
                    />
                  </div>
                </div>
              </Form.Group>
              {/* Unit */}
              <Form.Group className="mb-3">
                <div className="row">
                  <div className="col-md-2" style={{ marginTop: "8px" }}>
                    <Form.Label>Unit</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Typeahead
                      id="autocomplete-example"
                      labelKey="name"
                      options={units}
                      placeholder="Type to search..."
                      //   selected={selectedUnit}
                      onChange={handleSelection}
                      onInputChange={(e) => {
                        setSelectedUnit({ name: e });
                        setSession({ ...session, unit: e });
                      }}
                    />
                  </div>
                </div>
              </Form.Group>

              {/* Teacher Id  */}
              <Form.Group className="mb-1">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Teacher Name</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <AutocompleteTeachers
                      session={session}
                      selectedTeacher={selectedTeacher}
                      setSelectedTeacher={setSelectedTeacher}
                      id="teacher_id"
                    />
                  </div>
                </div>
              </Form.Group>
              {/* Pack id  */}
              {/* <Form.Group className="mb-3">
							<Form.Label>Pack Id</Form.Label>
							<AutocompletePacks
								session={session}
								selectedPack={selectedPack}
								setSelectedPack={setSelectedPack}
								id="pack_id"
							/>
						</Form.Group> */}

              {/* Session Location  */}
              <Form.Group className="mb-2">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Session Location</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Select
                      aria-label="Default select example"
                      id="location"
                      value={session?.location || "classroom"}
                      onChange={(e) =>
                        setSession({ ...session, location: e.target.value })
                      }
                    >
                      <option value="classroom">CLASSROOM</option>
                      <option value="online">ONLINE</option>
                    </Form.Select>
                  </div>
                </div>
              </Form.Group>

              {/*Session Capacity*/}
              <Form.Group className="mb-2">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Capacity</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Control
                      type="number"
                      placeholder="Capacity Here"
                      id="capacity"
                      value={session.capacity}
                      onChange={(e) =>
                        setSession({ ...session, capacity: e.target.value })
                      }
                    />
                  </div>
                </div>
              </Form.Group>
              {/* active  */}
              <Form.Group className="mb-1">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Session Status</Form.Label>
                  </div>
                  <div
                    className="col-md-10"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Form.Check
                      type="radio"
                      label="active"
                      name="active"
                      checked={session.active === true}
                      onChange={() => setSession({ ...session, active: true })}
                    />
                    <Form.Check
                      style={{ marginLeft: "5%" }}
                      type="radio"
                      label="inactive"
                      name="inactive"
                      checked={session.active === false}
                      onChange={() => setSession({ ...session, active: false })}
                    />
                  </div>
                </div>
              </Form.Group>
              {/* Start Date  */}
              <Form.Group className="mb-3">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Start Date</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Control
                      type="datetime-local"
                      placeholderText="Start date and time"
                      id="start_date"
                      value={session.start_date}
                      onChange={(e) => handleStartDateChange(e)}
                    />
                  </div>
                </div>
              </Form.Group>

              {/* End Date  */}
              <Form.Group className="mb-3">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>End Date</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Control
                      type="datetime-local"
                      placeholderText="End date and time"
                      id="end_date"
                      value={session.end_date}
                      selected={session.end_date}
                      onChange={(e) =>
                        setSession({ ...session, end_date: e.target.value })
                      }
                    />
                  </div>
                </div>
              </Form.Group>
              {/* Description  */}

              <Form.Group className="mb-3">
                <div className="row">
                  <div className="col-md-2">
                    <Form.Label>Description</Form.Label>
                  </div>
                  <div className="col-md-10">
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Session Description"
                      id="description"
                      value={session.description}
                      onChange={(e) =>
                        setSession({ ...session, description: e.target.value })
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <div className="mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  id="add-new-event-btn"
                  disabled={loading}
                  onClick={(e) => handleAddSession(e)}
                >
                  {sessionId ? "Update Session" : "Add New Session"}
                </Button>
                <Button
                  type="submit"
                  style={{ marginLeft: "1%" }}
                  variant="secondary"
                  id="add-new-event-btn"
                  disabled={loading}
                  onClick={(e) => handleAddSessionLeave(e)}
                >
                  {sessionId
                    ? "Update Session and leave"
                    : "Add Session and leave"}
                </Button>

                <Button className="ms-2" variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </Fragment>
  );
};

export default AddNewSession;
