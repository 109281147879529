export const AUTH = "AUTH";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const CLEAR_CURENT = "CLEAR_CURENT";

/* //packs constants
export const GET_ALL_PAKCS = "GET_ALL_PAKCS";
export const GET_PACK_DETAILS = "GET_PACK_DETAILS";
export const GET_BOOKS_IN_PACK = " GET_BOOKS_IN_PACK"; */

//Users constants
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";

//Approve User
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_TEACHER_SUCCESS = "APPROVE_TEACHER_SUCCESS";

//Teachers constants
export const GET_ALL_TEACHERS = "GET_ALL_TEACHERS";
export const GET_TEACHER = "GET_TEACHER";
export const EDIT_TEACHER = "EDIT_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const CREATE_TEACHER = "CREATE_TEACHER";

//Assistants constants
export const GET_ALL_ASSISTANTS = "GET_ALL_ASSISTANTS";
export const GET_ASSISTANT = "GET_ASSISTANT";
export const EDIT_ASSISTANT = "EDIT_ASSISTANT";
export const DELETE_ASSISTANT = "DELETE_ASSISTANT";
export const CREATE_ASSISTANT = "CREATE_ASSISTANT";

//Packs constants
export const GET_ALL_PACKS = "GET_ALL_PACKS";
export const GET_PACK = "GET_PACK";
export const EDIT_PACK = "EDIT_PACK";
export const DELETE_PACK = "DELETE_PACK";
export const CREATE_PACK = "CREATE_PACK";
export const GET_BOOKS_IN_PACK = "GET_BOOKS_IN_PACK";
export const ADD_BOOKS_IN_PACK = "ADD_BOOKS_IN_PACK";
export const DELETE_BOOKS_FROM_PACK = "DELETE_BOOKS_FROM_PACK";
export const GENERATE_CODE = "GENERATE_CODE";

//Books constants
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const EDIT_BOOK = "EDIT_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const CREATE_BOOK = "CREATE_BOOK";
// book text constats
export const GET_BOOK_TEXT = "GET_BOOK_TEXT";
export const EDIT_BOOK_TEXT = "EDIT_BOOK_TEXT";
export const CREATE_BOOK_TEXT = "CREATE_BOOK_TEXT";

//Sessions constants
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_SESSION = "GET_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const GET_SESSIONS_FROM_BOOK = "GET_SESSIONS_FROM_BOOK";
export const GET_SESSIONS_BY_TEACHER = "GET_SESSIONS_BY_TEACHER";
export const GET_READER_IN_SESSION = "GET_READER_IN_SESSION";
export const PRESENCE_ACTION = "PRESENCE_ACTION";
export const FETCH_ALL_UNITS = "FETCH_ALL_UNITS";
export const CREATE_UNIT = "CREATE_UNIT";

//FollowedPacks constants
export const GET_ALL_FOLLOWED_PACKS = "GET_ALL_FOLLOWED_PACKS";
export const APPROVE_FOLLOWED_PACK = "APPROVE_FOLLOWED_PACK";
export const DELETE_FOLLOWED_PACK = "DELETE_FOLLOWED_PACK";
export const REJECT_FOLLOWED_PACK = "REJECT_FOLLOWED_PACK";

//FollowedSessions constants
export const GET_ALL_FOLLOWED_SESSIONS = "GET_ALL_FOLLOWED_SESSIONS";
export const APPROVE_FOLLOWED_SESSION = "APPROVE_FOLLOWED_SESSION";
export const DELETE_FOLLOWED_SESSION = "DELETE_FOLLOWED_SESSION";
export const REJECT_FOLLOWED_SESSION = "REJECT_FOLLOWED_SESSION";

//QUizs constants
export const GET_ALL_QUIZS = "GET_ALL_QUIZS";
export const GET_ONE_QUIZ = "GET_ONE_QUIZ";
export const GET__QUIZS_IN_SESSION = "GET__QUIZS_IN_SESSION";
export const ADD__QUIZS_TO_SESSION = "ADD__QUIZS_TO_SESSION";
export const DELETE__QUIZS_FROM_SESSION = "DELETE__QUIZS_FROM_SESSION";
export const GET_RESULT_IN_SESSION = "GET_RESULT_IN_SESSION";
export const GET_ESSAY_QUESTIONS = "GET_ESSAY_QUESTIONS";
export const VALIDATE_ESSAY_QUESTIONS = "VALIDATE_ESSAY_QUESTIONS";
export const GET_RESULT_FOR_EACH_READER = "GET_RESULT_FOR_EACH_READER";
export const ADD__QUIZ = "ADD__QUIZ";
export const CREATE_QUIZ = "CREATE_QUIZ";
export const DELETE_QUIZ = "DELETE_QUIZ";
export const UPDATE_QUIZ = "UPDATE_QUIZ";
export const CLEAR__QUIZ = "CLEAR_QUIZ";
export const QUESTION = "QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = " UPDATE_QUESTION";
export const CREATE_QUESTIONS = " CREATE_QUESTIONS";
export const CREATE_QUESTION = " CREATE_QUESTION";
//about book constants
export const GET_ABOUT_BOOK = "GET_ABOUT_BOOK";
export const CREATE_ABOUT_BOOK = "CREATE_ABOUT_BOOK";
export const UPDATE_ABOUT_BOOK = "UPDATE_ABOUT_BOOK";
export const DELETE_ABOUT_BOOK = "DELETE_ABOUT_BOOK";

//Codes
export const GET_CODES = "GET_CODES";
export const EDIT_CODES = "EDIT_CODES";

// Dashbord admin

export const FETCH_DASHBORD_ANALYTICS = "FETCH_DASHBORD_ANALYTICS";
export const FETCH_DASHBORD_ADMIN = "FETCH_DASHBORD_ADMIN";

//Notification
export const FETCH_NOTIFICATION_CATEGORY = "FETCH_NOTIFICATION_CATEGORY";
export const CREATE_NOTIFICATION_CATEGORY = "CREATE_NOTIFICATION_CATEGORY";
export const FETCH_ONE_NOTIFICATION_CATEGORY =
  "FETCH_ONE_NOTIFICATION_CATEGORY";
export const DELETE_NOTIFICATION_CATEGORY = "DELETE_NOTIFICATION_CATEGORY";
export const UPDATE_NOTIFICATION_CATEGORY = "UPDATE_NOTIFICATION_CATEGORY";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const FETCH_ONE_NOTIFICATION = "FETCH_ONE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

/* ADAPTATIVE LEARNINIG */
export const SPLIT_STORY = "SPLIT_STORY";

//CATEGORIES constants
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_WORD = "GET_WORD";
export const GET_JSON_list = "GET_JSON_list";

//online session
export const START_VIDEO_SESSION = "START_VIDEO_SESSION";
export const START_WHITE_BOARD = "START_WHITE_BOARD";

//invoices
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_ONE_INVOICE = "GET_ONE_INVOICE";
//shcools
export const CREATE_SHCOOL = "CREATE_SHCOOL";
export const UPDATE_SHCOOL = "UPDATE_SHCOOL";
export const DELETE_SHCOOL = "DELETE_SHCOOL";
export const GET_ALL_SHCOOLS = "GET_ALL_SHCOOLS";
export const GET_ONE_SHCOOL = "GET_ONE_SHCOOL";
//templates
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_ONE_TEMPLATE = "GET_ONE_TEMPLATE";
