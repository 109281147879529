// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import custom components
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';

// import sub components
import UsersList from './UsersList';
import UsersGrid from './UsersGrid';
import AddNewStudent from './AddNewStudent';
import ReaderActive from './ReaderActive';
import ReaderInactive from './ReaderInactive';


const Students = () => {
	const [userId, setUserId] = useState(null)
	//calendar
	const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
	useEffect(() => {
		if (userId) setShowEventOffcanvas(true)
	}, [userId])

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="all">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Readers 
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User management</Breadcrumb.Item>
									<Breadcrumb.Item active>Readers</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
						<Col className="d-flex justify-content-end">
							<div>
							      	<AddNewStudent
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setUserId={setUserId}
									userId={userId} 
									


								/>
								
							</div>
						</Col>
					</Col>
				</Row>
				<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											Active Readers
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
										Inactive Readers
										</Nav.Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											List of User
										</Nav.Link>
									</Nav.Item> */}
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<ReaderActive setUserId={setUserId} />
									</Tab.Pane>
									<Tab.Pane eventKey="approved" className="pb-4">
										<ReaderInactive setUserId={setUserId} />
									</Tab.Pane>
									{/* <Tab.Pane eventKey="pending" className="pb-4">
										
									</Tab.Pane> */}
								</Tab.Content>
							</Card.Body>
			</Tab.Container>
			<ToastContainer />
		</Fragment>
		
	);
};

export default Students;
