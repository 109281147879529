// import node module libraries
import { Fragment, useState, useEffect, useRef } from "react";
import { Form, Button, Offcanvas, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { fetchAllUsersAction } from "actions/users";
import { fetchAllSessionsAction } from "actions/sessions";
import { createFollowSessionAction } from "actions/followedSession";

const AddFollowSession = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [follow, setFollow] = useState({
    user_id: null,
    session_id: null,
  });

  const { users } = useSelector((state) => state.users);
  const { sessions } = useSelector((state) => state.sessions);
  useEffect(() => {
    dispatch(fetchAllUsersAction());
    dispatch(fetchAllSessionsAction());
  }, [dispatch]);

  const handleSessionSelection = (selected) => {
    if (selected.length > 0) {
      setFollow({ ...follow, session_id: selected[0].id });
    }
  };
  const handleUserSelection = (selected) => {
    if (selected.length > 0) {
      setFollow({ ...follow, user_id: selected[0].id });
    }
  };

  const handleAddFollow = async (e) => {
    dispatch(createFollowSessionAction(follow, handleClose));
    e.preventDefault();
  };

  const handleClose = () => {
    setFollow({
      user_id: null,
      pack_id: null,
    });

    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Assigne Student
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Assigne Student</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* User  */}
            <Form.Group className="mb-3">
              <Form.Label>Student</Form.Label>
              <Typeahead
                id="autocomplete-example"
                labelKey={(option) => `${option.username} (${option.email})`}
                options={users}
                placeholder="Type to search..."
                onChange={handleUserSelection}
              />
            </Form.Group>

            {/* Pack  */}
            <Form.Group className="mb-3">
              <Form.Label>Session</Form.Label>

              <Typeahead
                id="autocomplete-example"
                labelKey="name"
                options={sessions}
                placeholder="Type to search..."
                onChange={handleSessionSelection}
              />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddFollow(e)}
              >
                save
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddFollowSession;
