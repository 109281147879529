// import node module libraries
import React, { Fragment, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useLocation, useNavigate} from "react-router-dom";
import {
  Col,
  Row,
  Table,
} from "react-bootstrap";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { getSessionByTeacherAction } from "actions/sessions";
import { reformatISODate, reformatSearchDate } from "helper/utils";

const SessionsDetailsTable = ({ selectedDate }) => {
    const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const teacher_id = searchParams.get("teacher_id");
  const dispatch = useDispatch();
  const { sessions } = useSelector((state) => state.sessions);
  useEffect(() => {
    dispatch(getSessionByTeacherAction(teacher_id));
  }, [dispatch, teacher_id]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", show: true },
      {
        accessor: "name",
        Header: "Session Title",
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/books-management/sessions_details/session?session_id=${row.original.id}&teacher_id=${teacher_id}`}
            >
              <div className="d-lg-flex align-items-center">
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h5 className="mb-1 text-primary-hover">{value}</h5>
                </div>
              </div>
            </Link>
          );
        },
      },
      { accessor: "capacity", Header: "Capacity" },
      { accessor: "teacher_name", Header: "Teacher Name", show: false },
      {
        accessor: "active",
        Header: "Session Status",

        Cell: ({ value, row }) => {
          return (
            <Fragment>
              <DotBadge
                bg={
                  value === false ? "warning" : value === true ? "success" : ""
                }
              >
                {value ? "Active" : "Inactive"}
              </DotBadge>
            </Fragment>
          );
        },
      },
      {
        accessor: "start_date",
        Header: "Start",
        Cell: ({ value, row }) => {
          const formattedDate = reformatISODate(value);
          return (
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h5 className="mb-0">{formattedDate}</h5>
              </div>
            </div>
          );
        },
      },
      {
        accessor: "end_date",
        Header: "End",
        Cell: ({ value, row }) => {
          const formattedDate = reformatISODate(value);
          return (
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h5 className="mb-0">{formattedDate}</h5>
              </div>
            </div>
          );
        },
      },

    ],
    []
  );

  const data = useMemo(() => {
    let filteredSessions;
    
    if (!selectedDate) {
      filteredSessions = sessions;
    } else {
      filteredSessions = sessions.filter(
        (session) => reformatSearchDate(session.start_date) === reformatSearchDate(selectedDate)
      );
    }
  
    return filteredSessions.reverse();
  }, [sessions, selectedDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Session"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive overflow-y-hidden">
        <Table
          hover
          {...getTableProps()}
          className="text-nowrap table-centered"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default SessionsDetailsTable;
