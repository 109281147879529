
import { getAllInvoices, getInvoice } from "api";
import {
 
    GET_ALL_INVOICES,
  GET_ONE_INVOICE,
  LOADING,
} from "./constants";

export const fetchAllInvoicesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} = await getAllInvoices();
    
    dispatch({ type: GET_ALL_INVOICES, payload: data.data});

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchInvoiceAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} = await getInvoice(id);
    dispatch({ type: GET_ONE_INVOICE, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};