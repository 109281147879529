// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import {
	Image,
	Row,
	Col,
	Table,
} from 'react-bootstrap';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

// import utility file

// import data files

import DotBadge from 'components/elements/bootstrap/DotBadge';
import { useSelector } from 'react-redux';
import {  fetchAllTeachersAction } from 'actions/teachers';
import { useDispatch } from 'react-redux';
import { fetchAllFollowedSessionsAction } from 'actions/followedSession';

const ChooseReader = () => {
	const dispatch = useDispatch();
	const { teachers } = useSelector(state => state.teachers);
	useEffect(() => {
		dispatch(fetchAllTeachersAction())
	}, [dispatch])
    const { followedsessions } = useSelector(state => state.followedSession);
    useEffect(() => {
        dispatch(fetchAllFollowedSessionsAction())

    }, [dispatch]);

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));



	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'username',
				Header: 'Name',
				Cell: ({ value, row }) => {
					return (
                        <Link
                        className="text-inherit"
                        to={`/books-management/choose_reader/session?user_id=${row.original.user_id}&name=${row.original.username}`}
                      >
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
                        </Link>
					);
				}
			},
			{
				accessor: 'email',
				Header: 'Email',
				Cell: ({ value }) => {
					return value;
				}
			},
		],
		[]
	);
	const data = useMemo(() => followedsessions.filter(session => session.approved === true), [followedsessions]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div>
                <h2>
                    Choose Reader
                </h2>
            </div>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Reader"
						/>
					</Col>
				</Row>
			</div>

			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default ChooseReader;
