import {
  GET_ALL_SESSIONS,
  GET_SESSION,
  EDIT_SESSION,
  DELETE_SESSION,
  CREATE_SESSION,
  GET_SESSIONS_FROM_BOOK,
  GET_SESSIONS_BY_TEACHER,
  GET_READER_IN_SESSION,
  PRESENCE_ACTION,
  FETCH_ALL_UNITS,
  CREATE_UNIT,
} from "../actions/constants";

const sessionsReducer = (state = { sessions: [], session: {}, reader_in_session: [], units: [] }, action) => {
  switch (action.type) {
    case GET_ALL_SESSIONS:
      return { ...state, sessions: action?.payload.sessions };
    case GET_SESSION:
      return { ...state, session: action?.payload };
    case EDIT_SESSION:
      return {
        ...state,
        sessions: [
          ...state.sessions.filter((e) => e.id !== action.payload.id),
          action.payload,
        ],
      };
    case DELETE_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter((e) => e.id !== action.payload),
      };
    case CREATE_SESSION:
      return { ...state, sessions: [...state.sessions, action.payload] };
    case GET_SESSIONS_FROM_BOOK:
      return { ...state, sessions: action?.payload.sessions };
    case GET_SESSIONS_BY_TEACHER:
      return { ...state, sessions: action?.payload.sessions };
    case GET_READER_IN_SESSION:
      return { ...state, reader_in_session: action?.payload };
    case FETCH_ALL_UNITS:
        return { ...state, units: action?.payload };
    case CREATE_UNIT:
        return { ...state, units: [action?.payload,...state.units] };
    case PRESENCE_ACTION:
      const updatedReaderInSession = state.reader_in_session.map(item => {
        if (item.user_id === action.payload.id) {
          return { ...item, presence: !item.presence };
        }
        return item;
      });
      return { ...state, reader_in_session: updatedReaderInSession };
    default:
      return state;
  }
};

export default sessionsReducer;
