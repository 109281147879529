// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Card, Tab, Breadcrumb } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import custom components
import GridListViewButton from "components/elements/miscellaneous/GridListViewButton";

// import sub components
import InstructorsGridView from "./InstructorsGridCard";
import InstructorsListItems from "./InstructorsListItems";
import InstructorGrid from "./InstructorGrid";
import InstructorList from "./InstructorList";
import AddNewTeacher from "./AddNewTeacher";

const Instructor = () => {
  const [teacherId, setTeacherId] = useState(null);
  const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
  const handleCloseEventOffcanvas = () => setShowEventOffcanvas(false);
  useEffect(() => {
    if (teacherId) setShowEventOffcanvas(true);
  }, [teacherId]);
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="grid">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Instructor</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">User management</Breadcrumb.Item>
                  <Breadcrumb.Item active>Teachers</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div>
								<GridListViewButton keyGrid="grid" keyList="list" />
							</div> */}
            </div>
            <Col className="d-flex justify-content-end">
              <div>
                <AddNewTeacher
                  showOffcanvas={showEventOffcanvas}
                  setShowOffcanvas={setShowEventOffcanvas}
                  setTeacherId={setTeacherId}
                  teacherId={teacherId}
                />
              </div>
            </Col>
          </Col>
        </Row>

        <Tab.Content>
          <Tab.Pane eventKey="grid" className="pb-4">
            <InstructorList setTeacherId={setTeacherId} />
            {/* <InstructorsListItems/> */}
          </Tab.Pane>
          <Tab.Pane eventKey="list" className="pb-4">
            <Card className="mb-5 ">
              <Card.Body className="p-0">
                {/* <InstructorGrid /> */}
                {/* <InstructorsGridView/> */}
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};
export default Instructor;
