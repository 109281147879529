// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";
import { XCircle, MoreVertical, Edit, Trash } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import {
  fetchSessionFromPackAction,
  deleteSessionAction,
} from "actions/sessions";
import { reformatISODate } from "helper/utils";

const SessionsTable = ({ setSessionId,setSessionLength }) => {
  const userDataString = localStorage.getItem('userData');
	const userData = JSON.parse(userDataString);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const book_id = searchParams.get("book_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { sessions } = useSelector((state) => state.sessions);
  useEffect(() => {
    dispatch(fetchSessionFromPackAction(book_id));
  }, [dispatch, book_id]);
  useEffect(() => {
    setSessionLength(sessions.length);
  }, [sessions, book_id]);

  const handleDeleteSession = (id) => {
    setSessionToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteSession = () => {
    if (sessionToDelete && confirmationMessage === "DELETE SESSION") {
      dispatch(deleteSessionAction(sessionToDelete));
      setSessionToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));
 
  const ActionMenu = ({ id, setSessionId }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => setSessionId(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          {userData?.role == "admin" &&
          <Dropdown.Item eventKey="2" onClick={() => handleDeleteSession(id)}>
            {" "}
            <Trash
              size="15px"
              className="dropdown-item-icon"
              variant="danger"
            />{" "}
            Remove
          </Dropdown.Item>
  }
          <Dropdown.Item
            eventKey="2"
            onClick={() => navigate("/quizes/quiz-in-session/" + id)}
          >
            {" "}
            <XCircle
              size="15px"
              className="dropdown-item-icon"
              variant="danger"
            />{" "}
            Quizes
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", show: false },
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value, row }) => {
          return (
              <div className="d-lg-flex align-items-center">
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h5 className="mb-1 text-primary-hover">{value}</h5>
                </div>
              </div>
          );
        },
      },
      // { accessor: "capacity", Header: "Capacity" },
      // { accessor: "book_name", Header: "Book Name" },
      { accessor: "teacher_name", Header: "Teacher Name" },
      {
        accessor: "active",
        Header: "Session Status",

        Cell: ({ value, row }) => {
          return (
            <Fragment>
              <DotBadge
                bg={
                  value === false ? "warning" : value === true ? "success" : ""
                }
              >
                {value ? "Active" : "Inactive"}
              </DotBadge>
            </Fragment>
          );
        },
      },
       { accessor: "pack_id", Header: "Pack ID" },
      { accessor: "start_date", Header: "Start",
      Cell: ({ value, row }) => {
				const formattedDate = reformatISODate(value);
				return (
					<div className="d-flex align-items-center">
						<div className="ms-3">
							<h5 className="mb-0">
								{formattedDate}
							</h5>
						</div>
					</div>
				);
			} },
      { accessor: "end_date", Header: "End",
      Cell: ({ value, row }) => {
				const formattedDate = reformatISODate(value);
				return (
					<div className="d-flex align-items-center">
						<div className="ms-3">
							<h5 className="mb-0">
								{formattedDate}
							</h5>
						</div>
					</div>
				);
			} },

      /* 			{
        accessor: 'active',
        Header: 'Status',
        Cell: ({ value }) => {
          if (value === 1) {
            return (
              <Fragment>
                <Button
                  href="#"
                  variant="outline"
                  className="btn btn-outline-secondary btn-sm"
                >
                  Desactive
                </Button>{' '}
                <Button href="#" variant="success" className="btn-sm">
                  Active
                </Button>
              </Fragment>
            );
          }
          if (value === 0) {
            return (
              <Button href="#" variant="secondary" className="btn-sm">
                Change Status
              </Button>
            );
          }
        }
      }, */
      {
        accessor: "shortcutmenu",
        Header: "",
        Cell: ({ row }) => {
          return (
            <ActionMenu id={row.original.id} setSessionId={setSessionId} />
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => sessions.reverse(), [sessions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Session"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive overflow-y-hidden">
        <Table
          hover
          {...getTableProps()}
          className="text-nowrap table-centered"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this session?</p>
          <h4 className="text-secondary">
            you must write "DELETE SESSION" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant={confirmationMessage !== 'DELETE SESSION' ? 'secondary' : 'danger'}
            disabled={confirmationMessage !== "DELETE SESSION"}
            onClick={confirmDeleteSession}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default SessionsTable;
