import {
    GET_ALL_FOLLOWED_SESSIONS,
    APPROVE_FOLLOWED_SESSION,
    DELETE_FOLLOWED_SESSION,
    REJECT_FOLLOWED_SESSION,
  } from "../actions/constants";
  
  const followedSessionReducer = (state = { followedsessions:[] }, action) => {
    switch (action.type) {
      case GET_ALL_FOLLOWED_SESSIONS:
        return { ...state, followedsessions: action?.payload };
        
      case APPROVE_FOLLOWED_SESSION:
        return { ...state, followedsessions: state.followedsessions.map((e)=>{
          if (e.session_id === action.payload.session_id && e.user_id ===action.payload.user_id)
          {return {...e,approved:true}}
          else {
            return e
          }

        }) };
        case DELETE_FOLLOWED_SESSION: 
        return {  ...state, followedsessions: state.followedsessions.filter(e =>!( e.user_id == action.payload.user_id && e.session_id == action.payload.session_id)) }; 
     
        case REJECT_FOLLOWED_SESSION:
          return { ...state, followedsessions: state.followedsessions.map((e)=>{
            if (e.session_id === action.payload.session_id && e.user_id ===action.payload.user_id)
            {return {...e,approved:false}}
            else {
              return e
            }
  
          }) };
      default:
        return state;
    }
  };
  
  export default followedSessionReducer;
  