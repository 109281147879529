import { START_VIDEO_SESSION, START_WHITE_BOARD } from "../actions/constants";
  
  const onlineSessionReducer = (state = { videoSession: [], whiteBoard: [] }, action) => {
    switch (action.type) {
      case START_VIDEO_SESSION:
        return { ...state, videoSession: action?.payload };
      case START_WHITE_BOARD:
        return { ...state, whiteBoard: action?.payload };
      default:
        return state;
    }
  };
  
  export default onlineSessionReducer;