import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllBooks,
  getBookById,
  editBook,
  deleteBook,
  createBook,
  createAboutBook,
  updateAboutBook,
  deleteAboutBook,
  getAboutBook,
  createBookText,
  getBookText,
  updateBookText,
} from "api";
import {
  GET_ALL_BOOKS,
  GET_BOOK,
  EDIT_BOOK,
  DELETE_BOOK,
  CREATE_BOOK,
  LOADING,
  GET_ABOUT_BOOK,
  DELETE_ABOUT_BOOK,
  UPDATE_ABOUT_BOOK,
  CREATE_ABOUT_BOOK,
  CREATE_BOOK_TEXT,
  GET_BOOK_TEXT,
} from "./constants";

export const fetchAllBooksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllBooks();

    dispatch({ type: GET_ALL_BOOKS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getBookById(id);

    dispatch({ type: GET_BOOK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const editBookAction = (fromData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await editBook(fromData);

    dispatch({ type: EDIT_BOOK, payload: data?.data.book });
    handleClose();
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await deleteBook(id);

    dispatch({ type: DELETE_BOOK, payload: id });
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createBookAction = (fromData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await createBook(fromData);
    dispatch({ type: CREATE_BOOK, payload: data.book });
    handleClose();
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const createAboutBookAction = (fromData, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await createAboutBook(fromData, id);
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: CREATE_ABOUT_BOOK, payload: data.about });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const updateAboutBookAction = (fromData, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await updateAboutBook(fromData, id);
    toast.success(data.message, {
      autoClose: 1000,
    });

    dispatch({ type: UPDATE_ABOUT_BOOK, payload: data.about });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const deleteAboutBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteAboutBook(id);
    toast.success(data.message, {
      autoClose: 1000,
    });

    dispatch({ type: DELETE_ABOUT_BOOK, payload: id });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const getAboutBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAboutBook(id);
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: GET_ABOUT_BOOK, payload: data.about });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: GET_ABOUT_BOOK, payload: null });
    dispatch({ type: LOADING, payload: false });
  }
};

export const createBookTextAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createBookText(fromData);
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const getBookTextAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getBookText(id);

    dispatch({ type: GET_BOOK_TEXT, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: GET_BOOK_TEXT, payload: null });
    dispatch({ type: LOADING, payload: false });
  }
};
export const editBookTextAction =
  (id, formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateBookText(id, formData);
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
