// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
	Dropdown,
	Image,
	OverlayTrigger,
	Tooltip,
	Row,
	Col,
	Table,
	Button,
	Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Mail } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { absentAction, getReaderInSessionAction, presentAction } from 'actions/sessions';




const PresenceManagement = () => {


	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.auth);
    const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const session_id = searchParams.get("session_id");
	const { reader_in_session } = useSelector(state => state.reader_in_session);
	useEffect(() => {
		dispatch(getReaderInSessionAction(session_id))
	}, [dispatch]);
    const handlePresent = (id) => {
        dispatch(presentAction(id,session_id));
    }
    const handleAbsent = (id) => {
        dispatch(absentAction(id,session_id));
    }



	


	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	// const ActionMenu = ({ id, setUserId }) => {
	// 	return (
	// 		<Dropdown style={{ zIndex: 1000, position:"absolute" }}>
	// 			<Dropdown.Toggle as={CustomToggle}>
	// 				<MoreVertical size="15px" className="text-secondary" />
	// 			</Dropdown.Toggle>
	// 			<Dropdown.Menu align="end">
	// 				<Dropdown.Header>SETTINGS</Dropdown.Header>
	// 				<Dropdown.Item eventKey="1"
	// 					onClick={() => setUserId(id)}
	// 				>
	// 					{' '}

	// 					<Edit
	// 						size="15px"
	// 						className="dropdown-item-icon"
	// 					/>{' '}
	// 					Edit
	// 				</Dropdown.Item>
	// 				<Dropdown.Item eventKey="2"
	// 					onClick={() => handleDeleteUser(id)}
	// 				>
	// 					{' '}
	// 					<Trash
	// 						size="15px"
	// 						className="dropdown-item-icon"
	// 						variant="danger"
	// 						onClick={confirmDeleteUser}
							

	// 					/> Remove
	// 				</Dropdown.Item>
	// 			</Dropdown.Menu>
	// 		</Dropdown>
	// 	);
	// };

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'username',
				Header: 'Name',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<Image
								src={row.original.img}
								alt=""
								className="rounded-circle avatar-md me-2"
							/>
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},

			{
				accessor: 'email',
				Header: 'Email',
				Cell: ({ value }) => {
					return value;
				}
			},
            {
                accessor:  'presence',
                Header: 'Presence',
                Cell: ({ value,row }) => {
                    if (value === false) {
                        return (
                            <Fragment>
                                <Button 
                                onClick={() => handleAbsent(row.original.user_id)}
                                 variant="secondary"
								 disabled={loading}
								 className="btn-sm">
                                    Absent
                                </Button>
                            </Fragment>
                        );
                    }
                    if (value === true) {
                        return (
                            <Button
                            onClick={() => handlePresent(row.original.user_id)}
                                variant="success"
								disabled={loading}
                                className="btn-sm">
                                Present
                            </Button>
                        );
                    }
                }
            },
			
		],
		[]
	);

	const data = useMemo(() => reader_in_session, [reader_in_session]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Readers"
						/>
					</Col>
				</Row>
			</div>

			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			{/* <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Deletion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to delete this reader?</p>
					<h4 className="text-secondary" >you must write "DELETE READER" to confirm the deletion</h4>
					<input
						className="form-control"
						rows="3"
						placeholder="Enter confirmation message"
						value={confirmationMessage}
						onChange={(e) => setConfirmationMessage(e.target.value)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
						Cancel
					</Button>
					<Button variant="danger" onClick={confirmDeleteUser}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal> */}

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default PresenceManagement;
