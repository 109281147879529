// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Card, Tab, Breadcrumb } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstructorList from "./ShcoolsList";
import AddNewShcool from "./AddNewShcool";

const Shcool = () => {
  const [shcoolId, setShcoolId] = useState(null);
  const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
  useEffect(() => {
    if (shcoolId) setShowEventOffcanvas(true);
  }, [shcoolId]);
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="grid">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">School</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">School management</Breadcrumb.Item>
                  <Breadcrumb.Item active>Schools</Breadcrumb.Item>
                </Breadcrumb>
              </div>
    
            </div>
            <Col className="d-flex justify-content-end">
              <div>
                <AddNewShcool
                  showOffcanvas={showEventOffcanvas}
                  setShowOffcanvas={setShowEventOffcanvas}
                  setShcoolId={setShcoolId}
                  shcoolId={shcoolId}
                 
                />
              </div>
            </Col>
          </Col>
        </Row>

        <Tab.Content>
          <Tab.Pane eventKey="grid" className="pb-4">
            <InstructorList setShcoolId={setShcoolId} />
          </Tab.Pane>
          <Tab.Pane eventKey="list" className="pb-4">
            <Card className="mb-5 ">
              <Card.Body className="p-0">
        
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};
export default Shcool;
