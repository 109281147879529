import { createQuestionsAction } from "actions/quiz";

import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { Trash } from "react-feather";
import { useDispatch } from "react-redux";

const QuestionModal = ({ show, handleClose, title }) => {
  const dispatch = useDispatch();
  const [localQuestions, setLocalQuestions] = useState([]);

  const handleInputChange = (questionIndex, key, value) => {
    const updatedQuestions = [...localQuestions];
    updatedQuestions[questionIndex][key] = value;
    setLocalQuestions(updatedQuestions);
  };

  const handleSave = () => {
    dispatch(
      createQuestionsAction(
        { categoryName: title, questions: localQuestions },
        handleClose
      )
    );
  };

  const handleImportQuestions = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          if (Array.isArray(jsonData)) {
            setLocalQuestions(jsonData);
          } else {
            alert(
              "Invalid file format. Please upload a JSON file with an array of questions."
            );
          }
        } catch (error) {
          alert("Error parsing JSON file. Please ensure it is valid.");
        }
      };
      reader.readAsText(file);
    }
  };

  const renderQuestionInputs = (question, index) => {
    switch (question.type) {
      case "checkbox":
        return (
          <Form>
            {question.data.map((item, itemIndex) => (
              <InputGroup key={item._id} className="mb-3">
                <Form.Check
                  type="checkbox"
                  checked={item.correct === "true"}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].correct =
                      e.target.checked.toString();
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Form.Control
                  style={{
                    border: "none",
                    boxShadow: "none",
                    margin: "-7px 0 0 2px",
                  }}
                  value={item.content}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].content =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(itemIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({
                  _id: Date.now().toString(), // Unique ID
                  content: "",
                  correct: "false",
                });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Option
            </Button>
          </Form>
        );

      case "multi-choice":
        return (
          <Form>
            {question.data.map((item, itemIndex) => (
              <InputGroup key={item._id} className="mb-3">
                <Form.Check
                  type="radio"
                  name={`multi-choice-${index}`}
                  checked={item.correct === "true"}
                  onChange={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.forEach(
                      (option) => (option.correct = "false")
                    );
                    updatedQuestions[index].data[itemIndex].correct = "true";
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Form.Control
                  value={item.content}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    margin: "-7px 0 0 2px",
                  }}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].content =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(itemIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({
                  _id: Date.now().toString(), // Unique ID
                  content: "",
                  correct: "false",
                });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Option
            </Button>
          </Form>
        );

      case "true-false":
        return (
          <Form>
            <Form.Check
              type="radio"
              label="True"
              name={`true-false-${index}`}
              className="mb-2"
              defaultChecked={question.data.correct === "true"}
              onChange={() =>
                handleInputChange(index, "data", { correct: "true" })
              }
            />
            <Form.Check
              type="radio"
              label="False"
              name={`true-false-${index}`}
              className="mb-2"
              defaultChecked={question.data.correct === "false"}
              onChange={() =>
                handleInputChange(index, "data", { correct: "false" })
              }
            />
          </Form>
        );
      case "order-the-phrase":
        return (
          <Form.Group>
            {question.data.map((phrase, phraseIndex) => (
              <InputGroup key={phraseIndex} className="mb-3">
                <Form.Control
                  value={phrase}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[phraseIndex] = e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(phraseIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push("");
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Phrase
            </Button>
          </Form.Group>
        );
      case "matching":
        return (
          <Form.Group>
            {question.data.map((pair, pairIndex) => (
              <InputGroup key={pairIndex} className="mb-3">
                <Form.Control
                  value={pair.left}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[pairIndex].left =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                  placeholder="Left"
                />
                <Form.Control
                  value={pair.right}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[pairIndex].right =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                  placeholder="Right"
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(pairIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({ left: "", right: "" });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Pair
            </Button>
          </Form.Group>
        );
      case "fill-in-the-blank":
        return (
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              value={question.data.text}
              className="mb-3"
              onChange={(e) =>
                handleInputChange(index, "data", {
                  ...question.data,
                  text: e.target.value,
                })
              }
            />
            {question.data.blanks.map((blank, blankIndex) => (
              <InputGroup key={blankIndex} className="mb-3">
                <Form.Control
                  value={blank}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.blanks[blankIndex] =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.blanks.splice(blankIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.blanks.push("");
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Blank
            </Button>
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {localQuestions.map((question, index) => (
          <div key={index} className="mb-4">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your question here"
                value={question.title} // Assuming `question.title` holds the question text
                onChange={(e) => {
                  const updatedQuestions = [...localQuestions];
                  updatedQuestions[index].title = e.target.value;
                  setLocalQuestions(updatedQuestions);
                }}
                style={{
                  border: "none", // Removes border
                  boxShadow: "none", // Removes focus shadow
                  padding: 0,
                  color: "black",
                  fontSize: "15px",
                  fontWeight: 600,
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your question description here"
                value={question.desc} // Assuming `question.description` holds the question text
                onChange={(e) => {
                  const updatedQuestions = [...localQuestions];
                  updatedQuestions[index].desc = e.target.value;
                  setLocalQuestions(updatedQuestions);
                }}
                style={{
                  border: "none", // Removes border
                  boxShadow: "none", // Removes focus shadow
                  padding: 0,
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Mark</Form.Label>
              <Form.Control
                type="number"
                value={question.mark}
                className="mb-3"
                onChange={(e) =>
                  handleInputChange(index, "mark", e.target.value)
                }
              />
            </Form.Group>
            {renderQuestionInputs(question, index)}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Form.Group>
          <Form.Label>Import Questions JSON</Form.Label>
          <Form.Control
            type="file"
            accept="application/json"
            onChange={handleImportQuestions}
          />
        </Form.Group>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionModal;
