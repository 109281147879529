// import node module libraries
import { Fragment, useState, useEffect, useRef } from "react";
import { Form, Button, Offcanvas, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createFollowPackAction } from "actions/followedPack";
import { Typeahead } from "react-bootstrap-typeahead";
import { fetchPacksBySchoolAction } from "actions/packs";
import { fetchAllUsersAction } from "actions/users";

const AddFollowPack = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state) => state.auth);
  const [follow, setFollow] = useState({
    user_id: null,
    pack_id: null,
  });
  const { packs } = useSelector((state) => state.packs);
  const { users } = useSelector((state) => state.users);
  useEffect(() => {
    dispatch(fetchPacksBySchoolAction(user.school_id));
    dispatch(fetchAllUsersAction());
  }, [dispatch]);

  const handlePackSelection = (selected) => {
    if (selected.length > 0) {
      setFollow({ ...follow, pack_id: selected[0].id });
    }
  };
  const handleUserSelection = (selected) => {
    if (selected.length > 0) {
      setFollow({ ...follow, user_id: selected[0].id });
    }
  };

  const handleAddFollow = async (e) => {
    dispatch(createFollowPackAction(follow, handleClose));
    e.preventDefault();
  };

  const handleClose = () => {
    setFollow({
      user_id: null,
      pack_id: null,
    });

    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Assigne Student
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Assigne Student</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* User  */}
            <Form.Group className="mb-3">
              <Form.Label>Student</Form.Label>
              <Typeahead
                id="autocomplete-example"
                labelKey={(option) => `${option.username} (${option.email})`}
                options={users}
                placeholder="Type to search..."
                onChange={handleUserSelection}
              />
            </Form.Group>

            {/* Pack  */}
            <Form.Group className="mb-3">
              <Form.Label>Pack</Form.Label>

              <Typeahead
                id="autocomplete-example"
                labelKey="title"
                options={packs}
                placeholder="Type to search..."
                onChange={handlePackSelection}
              />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddFollow(e)}
              >
                save
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddFollowPack;
