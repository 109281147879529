// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import custom components
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { Typeahead } from 'react-bootstrap-typeahead';

//import component

import AutocompletePack from './AutocompletePack';
import { useParams } from 'react-router-dom';
import { addBookInPackAction } from 'actions/packs';

const AddBookToPack = ({ showModal, setShowModal }) => {
	const { loading } = useSelector((state) => state.auth);
	const dispatch = useDispatch()
	const [selectedOption, setSelectedOption] = useState([]);
	
	const {id : pack_id} = useParams()

	const book_id = selectedOption[0]?.id;
	
	const handleClose = () => {
		setShowModal(false)
	}
	const handleAddBookInPack = async (e) => {
		e.preventDefault()
            dispatch(addBookInPackAction(book_id,pack_id, handleClose,setSelectedOption));
        

	}

	
	const handleShow = () => setShowModal(true);


	return (
		<Fragment>
			<Button variant="primary" onClick={handleShow}>
				Add Book To this Pack
			</Button>

			<Offcanvas
				show={showModal}
				onHide={handleClose}
				placement="end">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Add Book To this Pack</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form>
						{/* Title  */}
						<Form.Group className="mb-3">
							<Form.Label>Book Title</Form.Label>
							<AutocompletePack
							selectedOption = {selectedOption}
							setSelectedOption = {setSelectedOption}
							/>
						</Form.Group>
						<div className="mt-3">
							<Button type="submit"
								variant="primary"
								id="add-new-event-btn"
								disabled={loading}
							    onClick={(e) => handleAddBookInPack(e)}
							>
								Add Book To this Pack
							</Button>

							<Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
								Close
							</Button>
						</div>
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Fragment>
	);
};

export default AddBookToPack;
