import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllUsers,
  getUserById,
  editUser,
  deleteUser,
  createUser,
  getFollowers,
} from "api";
import {
  GET_ALL_USERS,
  GET_USER,
  EDIT_USER,
  DELETE_USER,
  CREATE_USER,
  LOADING,
} from "./constants";

export const fetchAllUsersAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllUsers();

    dispatch({ type: GET_ALL_USERS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchFollowersAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getFollowers(id);

    dispatch({ type: GET_ALL_USERS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ALL_USERS, payload: [] });
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchUserAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getUserById(id);

    dispatch({ type: GET_USER, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const editUserAction =
  (fromData, handleClose, setUserId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editUser(fromData);
      dispatch({ type: EDIT_USER, payload: data?.teacher });
      handleClose();
      setUserId();
      toast.success(data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteUserAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteUser(id);
    dispatch({ type: DELETE_USER, payload: id });

    toast.success(data.message, { autoClose: 1000 });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createUserAction = (fromData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await createUser(fromData);
    //  await createInvoiceClient(fromData)
    dispatch({ type: CREATE_USER, payload: data.user });
    toast.success(data.message, {
      autoClose: 1000,
    });
    handleClose();
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
