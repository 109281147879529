import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_CATEGORY,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_CATEGORY,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_CATEGORY,
  FETCH_ONE_NOTIFICATION,
  FETCH_ONE_NOTIFICATION_CATEGORY,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_CATEGORY,
} from "actions/constants";

const NotificationReducer = (
  state = {
    categories: [],
    notifications: [],
    category: {},
    notification: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_CATEGORY:
      return { ...state, categories: action?.payload };
    case FETCH_ONE_NOTIFICATION_CATEGORY:
      return { ...state, category: action?.payload };
    case UPDATE_NOTIFICATION_CATEGORY:
      return {
        ...state,
        categories: [
          action.payload,
          ...state.categories.filter((e) => e._id !== action.payload._id),
        ],
      };
    case DELETE_NOTIFICATION_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((e) => e._id !== action.payload),
      };
    case CREATE_NOTIFICATION_CATEGORY:
      return { ...state, categories: [action.payload, ...state.categories] };
    case FETCH_NOTIFICATION:
      return { ...state, notifications: action?.payload };
    case FETCH_ONE_NOTIFICATION:
      return { ...state, notification: action?.payload };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: [
          ...state.notification.filter((e) => e._id !== action.payload.id),
          action.payload,
        ],
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (e) => e._id !== action.payload
        ),
      };
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    default:
      return state;
  }
};

export default NotificationReducer;
