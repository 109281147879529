import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getJson,
  getJsonList,
  getWord,
  saveJson,
  splitStory,
  updateJson,
} from "api";
import { GET_JSON_list, GET_WORD, LOADING, SPLIT_STORY } from "./constants";

export const splitStoryAction = (story, title) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const fromData = { text: story };
    const { data } = await splitStory(fromData);

    await updateJson({ title: title, words: data.words }, title);
    dispatch({ type: SPLIT_STORY, payload: data.words });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const getJsonAction = (title) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getJson(title);

    dispatch({ type: SPLIT_STORY, payload: data.words });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const updateJsonAction = (story) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await updateJson(story, story.title);

    dispatch({ type: SPLIT_STORY, payload: data.words });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const getWordAction = (word) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getWord(word);
    let resWord = {
      lemma: data.res.c.lemma,
      length: data.res.c.length,
      pos: data.res.c.pos,
      word: data.res.c.word,
      level: data.res.level.level,
    };
    dispatch({ type: GET_WORD, payload: resWord });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: GET_WORD, payload: null });
    if (error?.response?.data?.message) {
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getJsonListAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getJsonList(id);

    dispatch({ type: GET_JSON_list, payload: data.fileNames });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
