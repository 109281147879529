// import node module libraries
import { Link } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Image, Dropdown, ListGroup } from "react-bootstrap";

// simple bar scrolling used for notification item scrolling
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// import data files

import { useSelector, useDispatch } from "react-redux";
import { logOutAction } from "actions/auth";
import { NOTIFICATION_KEY } from "api";
import {
  fetchUserNotificationAction,
  newNotificationAction,
  updateNotificationAction,
} from "actions/notification";
import { getTimeValue } from "helper/utils";
import { io } from "socket.io-client";
const socket = io("https://notification.iread.tn");
const QuickMenu = () => {
  const dispatch = useDispatch();
  const { user, notification } = useSelector((state) => state.auth) || {
    user: {},
    notification: [],
  };
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    const handleNotification = (noti) => {
      dispatch(newNotificationAction(noti));
    };

    socket.off("User" + user?.id + NOTIFICATION_KEY, handleNotification).off();

    socket.on("User" + user?.id + NOTIFICATION_KEY, handleNotification);

    return () => {
      socket
        .off("User" + user?.id + NOTIFICATION_KEY, handleNotification)
        .off();
      socket.disconnect();
    };
  }, []);

  const handleLogOut = (e) => {
    e.preventDefault();
    dispatch(logOutAction());
  };
  const handleReadNotification = (id) => {
    dispatch(updateNotificationAction({ isRead: true }, id));
  };
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchUserNotificationAction(user?.id));
    }
  }, [dispatch]);
  const Notifications = () => {
    return (
      <SimpleBar style={{ maxHeight: "300px" }}>
        <ListGroup variant="flush">
          {notification?.length > 0 ? (
            notification?.map(function (item, index) {
              return (
                <ListGroup.Item
                  className={item?.isRead ? "bg-light" : ""}
                  key={index}
                >
                  <Row>
                    <Col>
                      <Link className="text-body" to={item?.link}>
                        <div
                          className="d-flex"
                          onClick={() => {
                            if (item?.cat?.title == "User")
                              handleReadNotification(item?._id);
                          }}
                        >
                          <Image
                            src={item?.cat?.img}
                            alt="img"
                            className="avatar-md rounded-circle"
                          />
                          <div className="ms-3">
                            <h5 className="fw-bold mb-1">{item?.title}</h5>
                            <p className="mb-3">{item?.desc}</p>
                            <span className="fs-6 text-muted">
                              <span>
                                <span className="fe fe-thumbs-up text-success me-1"></span>
                                {getTimeValue(new Date(item?.createdAt))}
                              </span>
                              {/* <span className="ms-1">{item.time}</span> */}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })
          ) : (
            <div className="ms-3">
              <p className="mb-3">there are no notifications now</p>
            </div>
          )}
        </ListGroup>
      </SimpleBar>
    );
  };
  return (
    <Fragment>
      {/* <DarkLightMode /> */}
      <ListGroup
        as="ul"
        bsPrefix="navbar-nav"
        className="navbar-right-wrap ms-2 d-flex nav-top-wrap"
      >
        <Dropdown as="li">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            style={{
              backgroundColor: notification?.length > 0 ? "#754FFE" : "initial",
            }}
            className={`text-dark icon-notifications me-lg-1 btn btn-light btn-icon rounded-circle ${
              notification?.length === 0
                ? "indicator indicator-no-notifications text-muted"
                : "indicator indicator-primary"
            }`}
            id="dropdownNotification"
          >
            <i
              className="fe fe-bell"
              style={{
                color: notification?.length > 0 ? "white" : "initial",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-3 py-0"
            aria-labelledby="dropdownNotification"
            align="end"
          >
            <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
              <span className="h4 mb-0">Notifications</span>
              <Link to="# " className="text-muted">
                <span className="align-middle">
                  <i className="fe fe-settings me-1"></i>
                </span>
              </Link>
            </div>
            <Notifications />
            {/* <div className="border-top px-3 pt-3 pb-3">
            <Link
              to="/authentication/notifications"
              className="text-link fw-semi-bold"
            >
              See all Notifications
            </Link>
          </div> */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as="li" className="ms-1">
          <Dropdown.Toggle
            as="a"
            bsPrefix=" "
            className="rounded-circle"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image alt="avatar" src={user?.img} className="rounded-circle" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownUser"
            align="end"
          >
            <Dropdown.Item className="mt-3">
              <div className="d-flex">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <Image
                    alt="avatar"
                    src={user?.img}
                    className="rounded-circle"
                  />
                </div>
                <div className="ms-3 lh-1">
                  <h5 className="mb-1">{user?.username}</h5>
                  <p className="mb-0 text-muted">{user?.email}</p>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />

            {/* <Dropdown.Item eventKey="2">
               <Link to="/marketing/student/student-edit-profile">
                <i className="fe fe-user me-2"></i> Profile
              
            </Dropdown.Item>

            <Dropdown.Item eventKey="3">
              <i className="fe fe-star me-2"></i> Subscription
            </Dropdown.Item>
            <Dropdown.Item>
              <i className="fe fe-settings me-2"></i> Settings
            </Dropdown.Item>
            <Dropdown.Divider /> */}
            <Dropdown.Item className="mb-3" onClick={(e) => handleLogOut(e)}>
              <i className="fe fe-power me-2"></i> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ListGroup>
    </Fragment>
  );
};

export default QuickMenu;
