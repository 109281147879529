// import node module libraries
import { Card, Table, Image } from "react-bootstrap";
import Chrome from "assets/images/browser-svg/chrome.svg";
import Firefox from "assets/images/browser-svg/firefox.svg";
import Brave from "assets/images/browser-svg/brave.svg";
import Safari from "assets/images/browser-svg/safari.svg";
import IE from "assets/images/browser-svg/ie.svg";
import Opera from "assets/images/browser-svg/opera.svg";
// import data files
import BrowsersStatistics from "data/dashboard/BrowsersStatistics";

const Browsers = ({ title, data }) => {
  return (
    <Card className="h-100 ">
      <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{title}</h4>
      </Card.Header>
      <Card.Body className="p-0">
        <Table hover className="mb-0 text-nowrap table-centered">
          <tbody>
            {data?.map((item, index) => {
              let logo =
                item.browser == "Chrome Mobile" ||
                item.browser == "Chrome Mobile iOS" ||
                item.browser == "Chrome"
                  ? Chrome
                  : item.browser == "Edge"
                  ? IE
                  : Brave;
              return (
                <tr key={index}>
                  <td>
                    <Image src={logo} alt="" className="me-2" />{" "}
                    <span className="align-middle ">
                      {item.browser ? item.browser : "Unknown"}
                    </span>
                  </td>
                  <td>{item.percent}%</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
export default Browsers;
