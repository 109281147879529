import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editSession, startVideoSession, startWhiteBoard } from "api";
import {EDIT_SESSION, LOADING, START_VIDEO_SESSION, START_WHITE_BOARD,} from "./constants";
import { generateRandomKey } from 'helper/utils';

export const startVideoSessionAction = ({id,navigate}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
 
     const appId =generateRandomKey()
     const meet ={
      id:id,
      meet_link:"/"+appId+"/online-session"
     }
    const {data} = await editSession(meet)
    navigate(`/teacher-dashboard/sessions_meet?app_id=${appId}&session_name=${'online-session'}`)
    dispatch({ type: EDIT_SESSION, payload: data.session });
      
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const startWhiteBoardAction = () => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await startWhiteBoard();
      dispatch({ type: START_WHITE_BOARD, payload: data });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };