import { createQuestionAction } from "actions/quiz";

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash3 } from "react-bootstrap-icons";
import { Plus, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function AddQuestion({ setShow, type }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const searchParams = new URLSearchParams(location.search);
  const book_title = searchParams.get("book_title");

  const QuestionData = () => {
    switch (type) {
      case "true-false":
        return { correct: "true" };
      case "multi-choice":
        return [
          { _id: 1, content: "Choice 1", correct: "false" },
          { _id: 2, content: "Choice 2", correct: "true" },
        ];
      case "checkbox":
        return [
          { _id: 1, content: "Choice 1", correct: "true" },
          { _id: 2, content: "Choice 2", correct: "false" },
        ];
      case "order-the-phrase":
        return ["Phrase 1", "Phrase 2", "Phrase 3"];
      case "matching":
        return [
          { left: "Question 1", right: "Answer 1" },
          { left: "Question 2", right: "Answer 2" },
        ];
      case "fill-in-the-blank":
        return {
          text: "Fill in the ### blanks ### here.",
          blanks: ["blank1", "blank2"],
        };
      default:
        return null;
    }
  };

  const [question, setQuestion] = useState({
    title: "",
    desc: "",
    mark: 0,
    type: type,
    mark: 0,
    category: book_title,
    checkedBy: user.username,
    data: QuestionData(),
  });

  const handleOnChange = (updatedField) => {
    setQuestion({ ...question, ...updatedField });
  };

  const handleAddItem = (index) => {
    if (type === "multi-choice" || type === "checkbox") {
      setQuestion((prev) => ({
        ...prev,
        data: [...prev.data, { _id: index, content: "", correct: "false" }],
      }));
    } else if (type === "order-the-phrase") {
      setQuestion((prev) => ({
        ...prev,
        data: [...prev.data, `Phrase ${prev.data.length + 1}`],
      }));
    } else if (type === "matching") {
      setQuestion((prev) => ({
        ...prev,
        data: [...prev.data, { left: "", right: "" }],
      }));
    } else if (type === "fill-in-the-blank") {
      setQuestion((prev) => ({
        ...prev,
        data: { ...prev.data, blanks: [...prev.data.blanks, "new blank"] },
      }));
    }
  };

  const handleRemoveItem = (index) => {
    if (type == "fill-in-the-blank") {
      setQuestion((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          blanks: prev.data.blanks.filter((_, i) => i !== index),
        },
      }));
    } else {
      setQuestion((prev) => ({
        ...prev,
        data: prev.data.filter((_, i) => i !== index),
      }));
    }
  };

  const handleCreateQuestion = () => {
    dispatch(createQuestionAction(question, setShow));
  };

  return (
    <div>
      <Form.Group className="mb-3" style={{ width: "70%" }}>
        <Form.Label>Question Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter question title..."
          value={question.title}
          onChange={(e) => handleOnChange({ title: e.target.value })}
        />
      </Form.Group>
      <Form.Group className="mb-3" style={{ width: "70%" }}>
        <Form.Label>Question description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter description ..."
          value={question.desc}
          onChange={(e) => handleOnChange({ desc: e.target.value })}
        />
      </Form.Group>

      <Form.Group className="mb-3" style={{ width: "70%" }}>
        <Form.Label>Mark</Form.Label>
        <Form.Control
          type="number"
          placeholder="Mark"
          value={question.mark}
          onChange={(e) => handleOnChange({ mark: Number(e.target.value) })}
        />
      </Form.Group>

      {type === "multi-choice" || type === "checkbox" ? (
        <Form.Group>
          {question.data.map((choice, index) => (
            <div key={choice._id} className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder={`Choice ${index + 1}`}
                value={choice.content}
                onChange={(e) => {
                  const updatedChoices = [...question.data];
                  updatedChoices[index].content = e.target.value;
                  handleOnChange({ data: updatedChoices });
                }}
              />
              {type == "checkbox" && (
                <Form.Check
                  type="checkbox"
                  checked={choice.correct === "true"}
                  onChange={(e) => {
                    const updatedChoices = [...question.data];
                    updatedChoices[index].correct = e.target.checked
                      ? "true"
                      : "false";
                    handleOnChange({ data: updatedChoices });
                  }}
                  style={{ marginLeft: "5px" }}
                />
              )}
              {type == "multi-choice" && (
                <Form.Check
                  type="radio"
                  name="multi-choice-correct" // Ensure all radio buttons are grouped
                  checked={choice.correct === "true"}
                  onChange={() => {
                    const updatedChoices = question.data.map((item, i) => ({
                      ...item,
                      correct: i === index ? "true" : "false", // Mark only the selected one as true
                    }));
                    handleOnChange({ data: updatedChoices });
                  }}
                  style={{ marginLeft: "5px" }}
                />
              )}

              <Trash3
                style={{ color: "red", marginLeft: "10px" }}
                onClick={() => handleRemoveItem(index)}
              />
            </div>
          ))}
          <Plus
            onClick={() => handleAddItem(question.data.length + 1)}
            style={{ marginTop: "5px" }}
            size={18}
          />
        </Form.Group>
      ) : null}

      {type === "true-false" && (
        <Form.Group>
          <Form.Label>Select Answer</Form.Label>
          <Form.Check
            type="radio"
            label="True"
            checked={question.data.correct === "true"}
            onChange={() => handleOnChange({ data: { correct: "true" } })}
          />
          <Form.Check
            type="radio"
            label="False"
            checked={question.data.correct === "false"}
            onChange={() => handleOnChange({ data: { correct: "false" } })}
          />
        </Form.Group>
      )}

      {type === "order-the-phrase" && (
        <Form.Group>
          {question.data.map((phrase, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder={`Phrase ${index + 1}`}
                value={phrase}
                onChange={(e) => {
                  const updatedPhrases = [...question.data];
                  updatedPhrases[index] = e.target.value;
                  handleOnChange({ data: updatedPhrases });
                }}
              />

              <Trash
                onClick={() => handleRemoveItem(index)}
                style={{ color: "red", marginLeft: "5px" }}
                size={16}
              />
            </div>
          ))}
          <Button variant="link" onClick={handleAddItem}>
            <Plus /> Add Phrase
          </Button>
        </Form.Group>
      )}

      {type === "matching" && (
        <Form.Group>
          {question.data.map((pair, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder="Left"
                value={pair.left}
                onChange={(e) => {
                  const updatedPairs = [...question.data];
                  updatedPairs[index].left = e.target.value;
                  handleOnChange({ data: updatedPairs });
                }}
              />
              <Form.Control
                style={{ marginLeft: "5px" }}
                type="text"
                placeholder="Right"
                value={pair.right}
                onChange={(e) => {
                  const updatedPairs = [...question.data];
                  updatedPairs[index].right = e.target.value;
                  handleOnChange({ data: updatedPairs });
                }}
              />

              <Trash
                style={{ color: "red", marginLeft: "5px" }}
                onClick={() => handleRemoveItem(index)}
                size={30}
              />
            </div>
          ))}
          <Button variant="link" onClick={handleAddItem}>
            <Plus /> Add Pair
          </Button>
        </Form.Group>
      )}

      {type === "fill-in-the-blank" && (
        <Form.Group>
          <Form.Label>Text</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={question.data.text}
            onChange={(e) =>
              handleOnChange({
                data: { ...question.data, text: e.target.value },
              })
            }
          />
          {question.data.blanks.map((blank, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <Form.Control
                key={index}
                style={{ marginTop: "5px" }}
                type="text"
                placeholder="Right"
                value={blank}
                onChange={(e) => {
                  const updatedBlank = { ...question.data };
                  updatedBlank.blanks[index] = e.target.value;
                  handleOnChange({ data: updatedBlank });
                }}
              />
              <Trash
                onClick={() => handleRemoveItem(index)}
                style={{ color: "red", marginLeft: "5px" }}
                size={16}
              />
            </div>
          ))}
          <Button variant="link" onClick={handleAddItem}>
            <Plus /> Add Blank
          </Button>
        </Form.Group>
      )}

      <Button
        variant="primary"
        style={{ marginTop: "10px" }}
        onClick={handleCreateQuestion}
      >
        Save
      </Button>
      <Button
        variant="danger"
        style={{ marginTop: "10px", marginLeft: "10px" }}
        onClick={() => setShow(false)}
      >
        Close
      </Button>
    </div>
  );
}

export default AddQuestion;
