import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllCategories,
  getCategoryById,
  editCategory,
  deleteCategory,
  createCategory,
} from "api";
import {
  GET_ALL_CATEGORIES,
  GET_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  CREATE_CATEGORY,
  LOADING,
} from "./constants";

export const fetchAllCategoriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllCategories();

    dispatch({ type: GET_ALL_CATEGORIES, payload: data.categories });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchCategoryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getCategoryById(id);

    dispatch({ type: GET_CATEGORY, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const editCategoryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await editCategory(id);

    dispatch({ type: EDIT_CATEGORY, payload: data });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const deleteCategoryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteCategory(id);

    dispatch({ type: DELETE_CATEGORY, payload: id });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const createCategoryAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createCategory(fromData);
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: CREATE_CATEGORY, payload: data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
