import { GET_ALL_CATEGORIES } from "actions/constants";

  
  const categoryQuizReducer = (state = { quizCategories: [],quizCategorie:{} }, action) => {
    switch (action.type) {
      case GET_ALL_CATEGORIES:
        return { ...state,
          quizCategories: action?.payload
         };
      
      default:
        return state;
    }
  };
  
  export default categoryQuizReducer;
  