// import node module libraries
import { fetchEssayQuestionAction, validationEssayAction } from "actions/quiz";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Form, Container, Card, Button, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const CorrectionPage = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const { loading } = useSelector((state) => state.auth);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const user_id = searchParams.get("user_id");
  const session_id = searchParams.get("session_id");
  const { essayQuestions } = useSelector((state) => state.quizs);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token && user_id) {
      dispatch(fetchEssayQuestionAction(token, user_id));
    }
  }, [dispatch, token, user_id]);
  const [formData, setFormData] = useState({
    question: "",
    explanation: "",
    readerAnswer: "",
  });

  const [validation, setValidation] = useState({
    answer_token: token,
    teacher_approval: false,
    teacher_comments: "",
    teacher_checked: false,
    score: "",
    user_id: user_id,
  });
  const handleClose = () => {
    setValidation({
      answer_token: token,
      teacher_approval: false,
      teacher_comments: "",
      teacher_checked: false,
      score: "",
      user_id: user_id,
    });
  };
  const handlevalidation = () => {
    dispatch(validationEssayAction(validation, handleClose));
    navigate(`/sessions_details/reader_quizs?session_id=${session_id}&quiz_id=${user_id}`)
  };
  // const essayQuestions = [
  // 	{
  // 		answer_token: 1,
  // 		question_text: {
  // 			content: "Quelle est votre opinion sur le changement climatique?",
  // 			explanation: "Expliquez en détail votre point de vue sur le changement climatique.",
  // 		},
  // 		answer: "Ma réponse à la question sur le changement climatique.",
  // 	},
  // 	{
  // 		answer_token: 2,
  // 		question_text: {
  // 			content: "Quels sont les avantages et les inconvénients de la technologie moderne?",
  // 			explanation: "Discutez des aspects positifs et négatifs de la technologie moderne.",
  // 		},
  // 		answer: "Ma réponse à la question sur la technologie moderne.",
  // 	},
  // ];
  const filteredQuestion = essayQuestions.filter(
    (question) => question.answer_token === token
  );

  useEffect(() => {
    if (filteredQuestion) {
      setFormData({
        question: filteredQuestion[0]?.question_text?.content,
        explanation: filteredQuestion[0]?.question_text?.explanation,
        readerAnswer: filteredQuestion[0]?.answer,
        teacherMark: filteredQuestion[0]?.mark,
      });
    } else {
      console.log("Question not found");
    }
  }, []);
  const handleCheckboxChange = (field) => {
    setValidation((prevValidation) => ({
      ...prevValidation,
      [field]: !prevValidation[field],
    }));
  };

  const handleInputChange = (field, value) => {
    setValidation((prevValidation) => ({
      ...prevValidation,
      [field]: value,
    }));
  };
  const handleMarkChange = (field, value) => {
    const inputValue = parseFloat(value);

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= formData.teacherMark) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        [field]: inputValue,
      }));
    } else {
      const alertMessage = (
        <Alert variant="warning">
          <strong>"Invalid input. Please enter a valid number within the specified range <span style={{display:"inline-block", color:"red", fontSize:"18px"}}>Specify values within the interval [0, {formData.teacherMark}].</span></strong>
        </Alert>
      );

      setErrorMsg(alertMessage);

      setTimeout(() => setErrorMsg(null), 4000);
    };
  }

  return (
    <Fragment>
      <section className="py-4 py-lg-6 bg-primary">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1">Correction Essay Question</h1>
                  <p className="mb-0 text-white lead">
                    Just fill the form and correct the question.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Form>
        {/* Card */}
        <Card className="mb-3 ">
          {/* Card body */}
          <Card.Body>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="courseTitle">Question</Form.Label>
              <Form.Control
                type="text"
                id="course_title"
                value={formData.question}
                name="course_title"
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="courseTitle">Explanation</Form.Label>
              <Form.Control
                type="text"
                id="course_title"
                value={formData.explanation}
                name="course_title"
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="courseTitle">Reader Answer</Form.Label>
              <Form.Control
                type="text"
                id="course_title"
                value={formData.readerAnswer}
                name="course_title"
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="checkbox_id"
                label="answer approved ?"
                onChange={() => handleCheckboxChange("teacher_approval")}
              />
              <Form.Text className="text-muted">
                You should check this box
              </Form.Text>
            </Form.Group>

            {/* Category */}
            <Form.Group className="mb-3">
              <Form.Label>Teacher comment:</Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                placeholder="Teacher comment"
                id="course_title"
                name="course_title"
                onChange={(e) =>
                  handleInputChange("teacher_comments", e.target.value)
                }
              />
              <Form.Text className="text-muted">
                Leave a comment for this answer.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                id="checkbox_id"
                label="Teacher Checked ?"
                onChange={() => handleCheckboxChange("teacher_checked")}
              />
              <Form.Text className="text-muted">
                You should check this box
              </Form.Text>
            </Form.Group>

            {/* Courses level */}
            <Form.Group className="mb-3">
              <Form.Label>Teacher Mark:</Form.Label>
              {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
              <Form.Control
                type="number"
                placeholder="Mark"
                id="course_title"
                name="course_title"
                onChange={(e) => handleMarkChange("score", e.target.value)}
                style={{ width: "200px" }}
                min={0} 
                max={formData.teacherMark}
              />
              <Form.Text className="text-muted">
                Enter the teacher's mark for this answer. The maximum allowed
                mark is equal to <span style={{ color: "red", fontSize: "18px" }}> {formData.teacherMark}</span>.
              </Form.Text>
            </Form.Group>
          </Card.Body>
        </Card>
        {/* Button */}
        <Button variant="primary" disabled={loading} onClick={() => handlevalidation(validation)}>
          save and back to quiz page
        </Button>
      </Form>
    </Fragment>
  );
};

export default CorrectionPage;
