import { Button, Form, Image, Offcanvas } from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker"; // Import the date picker component
import "react-datepicker/dist/react-datepicker.css"; // Import the date picker styles
import { useDispatch } from "react-redux";
import {
  createTeacherAction,
  editTeacherAction,
  fetchTeacherAction,
} from "actions/teachers";
import { useSelector } from "react-redux";
import {
  createNotiCategoryAction,
  getNotiCategoryAction,
  updateNotificationCatAction,
} from "actions/notification";

const AddCategory = ({ showOffcanvas, setShowOffcanvas, catId, setCatId }) => {
  const { category } = useSelector((state) => state.notification);
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [newCategory, setNewCategory] = useState({
    title: "",
  });

  const [img, setImg] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
  );

  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;

    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dtwkikmuy",
        uploadPreset: "wuraurta",
      },
      (error, result) => {
        if (result.info.secure_url) {
          setImg(result.info.secure_url);
        }
      }
    );
  }, []);
  //cloudinary

  const handleClose = () => {
    setNewCategory({
      title: "",
    });
    setShowOffcanvas(false);
    setCatId(null);
  };
  const handleShow = () => setShowOffcanvas(true);

  const handleAddCat = async (e) => {
    if (catId) {
      dispatch(
        updateNotificationCatAction(
          { ...newCategory, img },
          category._id,
          handleClose
        )
      );
    } else {
      dispatch(createNotiCategoryAction({ ...newCategory, img }, handleClose));
    }
    e.preventDefault();
  };
  useEffect(() => {
    if (catId) {
      dispatch(getNotiCategoryAction(catId));
    }
  }, [dispatch, catId]);
  useEffect(() => {
    if (category._id) {
      setNewCategory({
        title: category.title,
      });
      setImg(category.img);
    }
  }, [category]);

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add New Category
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {catId ? "Update Category" : "Add New Category"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={img}
                  alt=""
                  className="rounded-circle me-3"
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
                <Button
                  style={{
                    marginTop: "21px",
                    width: "100%",
                  }}
                  variant="primary"
                  size="sm"
                  onClick={() => widgetRef.current.open()}
                  id="img"
                >
                  {category._id ? "Update Image" : "Upload New Image"}
                </Button>
              </div>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Category Name"
                id="username"
                required
                value={newCategory.title}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, title: e.target.value })
                }
              />
              <Form.Text className="text-muted">
                Field must contain a unique value
              </Form.Text>
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddCat(e)}
              >
                {catId ? "Update Category" : "Add New Category"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddCategory;
