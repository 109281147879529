// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
	Dropdown,
	Image,
	OverlayTrigger,
	Tooltip,
	Row,
	Col,
	Table,
	Button,
	Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Mail } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { getReaderInSessionAction } from 'actions/sessions';
import { fetchResultForEachReader, getResultInSessionAction } from 'actions/quiz';
import DotBadge from 'components/elements/bootstrap/DotBadge';




const ListReader = () => {

	const dispatch = useDispatch();
	const [dataToShowInColumns, setDataToShowInColumns] = useState([]);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get("session_id");
	// const { reader_in_session } = useSelector(state => state.reader_in_session);
	const { resultsForEachReader } = useSelector((state) => state?.quizs);
	useEffect(() => {
		dispatch(fetchResultForEachReader(id));
	}, [dispatch]);
	// useEffect(() => {
	// 	dispatch(getReaderInSessionAction(id))
	// }, [dispatch]);


	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	useEffect(() => {
		if (resultsForEachReader.length > 0) {
			const newDataToShowInColumns = Object.keys(resultsForEachReader[0]).filter(e => e !== "quiz_id");
			setDataToShowInColumns(newDataToShowInColumns);
		}
	}, [resultsForEachReader]);
	const columns = useMemo(

		() =>
			dataToShowInColumns?.map((e, key) => {
				return {
					accessor: e,
					Header: e,
					Cell: ({ value, row }) => {
						return (
							<Link to={`/sessions_details/reader_quizs?session_id=${id}&quiz_id=${row.original?.quiz_id}`}>
								<div className="d-flex align-items-center">
									{/* <Image
						  src={"row.original.img"}
						  alt=""
						  className="rounded-circle avatar-md me-2"
						/> */}
									{
										typeof value === 'string' ? (
											<h5 className="mb-0">{value}</h5>
										) : typeof value === 'boolean' ? (
											<h5 className="mb-0" style={{ color: value ? 'green' : 'red' }}>{value ? 'Present' : 'Absent'}</h5>
										) : (
											<h5 className="mb-0" style={{ color: value?.success ? 'green' : 'red' }}>
												{value?.precent === 'incomplete' || value?.precent === 'incorrected' ? '--' : value?.precent + '%'}
											</h5>
										)
									}

								</div>
							</Link>
						);
					},
				};
			}),
		[resultsForEachReader, id]
	);

	const data = useMemo(() => resultsForEachReader, [resultsForEachReader]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Readers"
						/>
					</Col>
				</Row>
			</div>
			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default ListReader;
