import { Button, Form, Image, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createAssistantAction, editAssistantAction, fetchAssistantAction } from 'actions/assistant';




const AddNewAssistant = ({ showOffcanvas, setShowOffcanvas, assistantId, setAssistantId }) => {
    const { assistant: assistantEdited } = useSelector(state => state.assistants)
    const dispatch = useDispatch();
    const {loading }= useSelector(state=>state.auth)
    const [assistant, setAssistant] = useState({
        username: "",
        email: "",
        password: "",

    });
    const [img, setImg] = useState("https://cdn-icons-png.flaticon.com/512/2883/2883826.png");
    //cloudinary
const cloudinaryRef = useRef();
const widgetRef = useRef();
useEffect(() => {
  cloudinaryRef.current = window.cloudinary;

  widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
          cloudName: "dtwkikmuy",
          uploadPreset: "wuraurta",
      },
      (error, result) => {
          if (result.info.secure_url) {

              setImg(result.info.secure_url)

          }
      }
  );
}, []);

    const handleClose = () => {
        setAssistant({
            username: "",
            email: "",
            password: "",
        })
        setShowOffcanvas(false)
        setAssistantId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddAssistant = async (e) => {
        if (assistantId) {
            dispatch(editAssistantAction({ ...assistant, img: img }, handleClose, setAssistantId))
        } else {
            dispatch(createAssistantAction({ ...assistant, img: img }, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (assistantId) {
            dispatch(fetchAssistantAction(assistantId))
        }
    }, [dispatch, assistantId])
    useEffect(() => {
        
        
        if (assistantEdited?.id) {
            setAssistant({
                username: assistantEdited.username,
                email: assistantEdited.email,
                id: assistantEdited.id,
            }) 
            setImg(
                assistantEdited.img
           )
                } 
               
    }, [assistantEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Add New Assistant
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{assistantId ? 'Update Assistant' : 'Add New Assistant'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Image  */}
                         <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '100px', 
                                        height: '100px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {assistantId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group> 
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Assistant Name"
                                id="name"
                                required
                                value={assistant.username}
                                onChange={(e) =>
                                    setAssistant({ ...assistant, username: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Assistant Email"
                                id="email"
                                value={assistant.email}
                                required
                                onChange={(e) =>
                                    setAssistant({ ...assistant, email: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* Releasing date  */}
                        {/* <Form.Group className="mb-3">
                          
                                <Form.Label>Joined At</Form.Label>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <DatePicker
                                    selected={selectedDate} // Pass the selected date value here
                                    onChange={handleDateChange} 
                                    placeholderText="Click Here" 
                                    className="form-control me-2" 
                                    id="release_date"
                                />
                            </div>
                        </Form.Group> */}
                        {/* PassWord  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder="**********"
                                id="password"
                                required
                                value={assistant.password}
                                onChange={(e) =>
                                    setAssistant({ ...assistant, password: e.target.value })
                                }
                            />


                        </Form.Group>

                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                disabled={loading}
                                onClick={(e) => handleAddAssistant(e)}>
                                {assistantId ? 'Update Assistant' : 'Add New Assistant'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewAssistant;