// import node module libraries
import { Fragment } from "react";
import { Link} from "react-router-dom";
import { Image, Card, ListGroup, Button } from "react-bootstrap";
// import custom components
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";
import GKAccordionDefault from "components/marketing/common/accordions/GKAccordionDefault";
import { addTemplateAction } from "actions/packs";
import { useDispatch } from "react-redux";


const TemplateCard = ({ item }) => {
    const dispatch = useDispatch()
  
    const GridView = () => {
 
        console.log(item)
        return (
            <Card
                className={`mb-4 card-hover `}
       
            >
                <Image
                    src={item.img}
                    alt={item?.title}
                    className="card-img-top rounded-top-md"
                    style={{ height: "300px" }}
                />

                {/* Card body  */}
                <Card.Body>
                    <h3 className="h4 mb-2 text-truncate-line-2 ">
                        <Link className="text-inherit">
                            {item?.title} Template
                        </Link>
                    </h3>
                    <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
                        <ListGroup.Item as="li" bsPrefix="list-inline-item">
                            <i className="fas fa-book me-1"></i>
                            {item.book_number} books
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix="list-inline-item">
                            <LevelIcon level={"Advance"} />
                            {item?.level}
                        </ListGroup.Item>
                    </ListGroup>
                    <GKAccordionDefault books={item.books} />
                    <div style={{ marginTop: '20px' }} >
                        <Button onClick={() => dispatch(addTemplateAction(item.id))}>
                            Add Template
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    return (
        <Fragment>
            <GridView />
        </Fragment>
    );
};



export default TemplateCard;
