import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CREATE_SHCOOL, DELETE_SHCOOL, GET_ALL_SHCOOLS, GET_ONE_SHCOOL, LOADING, UPDATE_SHCOOL } from "./constants";
import { createShcool, deleteShcool, fetchOneShcool, fetchallShcools, updateShcool } from "api";


export const fetchAllShcoolsAction= () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await fetchallShcools();
   
    dispatch({ type: GET_ALL_SHCOOLS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchOneShcoolAction = ( id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await fetchOneShcool( id);
    dispatch({ type: GET_ONE_SHCOOL, payload: data.shcool});
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });     
};
}
export const createShcoolAction = (shcool,handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await createShcool(shcool);
    dispatch({ type: CREATE_SHCOOL, payload: data.shcool });
     toast.success(data.message)
    dispatch({ type: LOADING, payload: false });
    handleClose()
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const updateShcoolAction = (shcool,id,handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await updateShcool(shcool,id);
      dispatch({ type: UPDATE_SHCOOL, payload: data.shcool });
       toast.success(data.message)
      dispatch({ type: LOADING, payload: false });
      handleClose()
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  export const deleteShcoolAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await deleteShcool(id); 
      dispatch({ type: DELETE_SHCOOL, payload: id });
       toast.success(data.message)
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

