import { deleteQuestionAction, updateQuestionAction } from "actions/quiz";
import React, { Fragment, useEffect, useState } from "react";
import { Form, InputGroup, Container, Button, Row, Col } from "react-bootstrap";
import { Check2Circle } from "react-bootstrap-icons";
import { Plus, Trash } from "react-feather";

import { useDispatch, useSelector } from "react-redux";
import AddQuestion from "./AddQuestion";

const Questions = ({ questions }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedType, setSelectedType] = useState("multi-choice");
  const { user, loading } = useSelector((state) => state.auth);
  const [localQuestions, setLocalQuestions] = useState(questions);
  const handleInputChange = (questionIndex, key, value) => {
    const updatedQuestions = [...localQuestions];
    updatedQuestions[questionIndex][key] = value;
    setLocalQuestions(updatedQuestions);
  };

  useEffect(() => {
    setLocalQuestions(questions);
  }, [questions]);
  const renderQuestionInputs = (question, index) => {
    switch (question.type) {
      case "checkbox":
        return (
          <Form>
            {question.data.map((item, itemIndex) => (
              <InputGroup key={item._id} className="mb-3">
                <Form.Check
                  type="checkbox"
                  checked={item.correct === "true"}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].correct =
                      e.target.checked.toString();
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Form.Control
                  style={{
                    border: "none",
                    boxShadow: "none",
                    margin: "-7px 0 0 2px",
                  }}
                  value={item.content}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].content =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(itemIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({
                  _id: Date.now().toString(), // Unique ID
                  content: "",
                  correct: "false",
                });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Option
            </Button>
          </Form>
        );

      case "multi-choice":
        return (
          <Form>
            {question.data.map((item, itemIndex) => (
              <InputGroup key={item._id} className="mb-3">
                <Form.Check
                  type="radio"
                  name={`multi-choice-${index}`}
                  checked={item.correct === "true"}
                  onChange={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.forEach(
                      (option) => (option.correct = "false")
                    );
                    updatedQuestions[index].data[itemIndex].correct = "true";
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Form.Control
                  value={item.content}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    margin: "-7px 0 0 2px",
                  }}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[itemIndex].content =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(itemIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({
                  _id: Date.now().toString(), // Unique ID
                  content: "",
                  correct: "false",
                });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Option
            </Button>
          </Form>
        );

      case "true-false":
        return (
          <Form>
            <Form.Check
              type="radio"
              label="True"
              name={`true-false-${index}`}
              className="mb-2"
              defaultChecked={question.data.correct === "true"}
              onChange={() =>
                handleInputChange(index, "data", { correct: "true" })
              }
            />
            <Form.Check
              type="radio"
              label="False"
              name={`true-false-${index}`}
              className="mb-2"
              defaultChecked={question.data.correct === "false"}
              onChange={() =>
                handleInputChange(index, "data", { correct: "false" })
              }
            />
          </Form>
        );
      case "order-the-phrase":
        return (
          <Form.Group>
            {question.data.map((phrase, phraseIndex) => (
              <InputGroup key={phraseIndex} className="mb-3">
                <Form.Control
                  value={phrase}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[phraseIndex] = e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(phraseIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push("");
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Phrase
            </Button>
          </Form.Group>
        );
      case "matching":
        return (
          <Form.Group>
            {question.data.map((pair, pairIndex) => (
              <InputGroup key={pairIndex} className="mb-3">
                <Form.Control
                  value={pair.left}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[pairIndex].left =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                  placeholder="Left"
                />
                <Form.Control
                  value={pair.right}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data[pairIndex].right =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                  placeholder="Right"
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.splice(pairIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.push({ left: "", right: "" });
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Pair
            </Button>
          </Form.Group>
        );
      case "fill-in-the-blank":
        return (
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              value={question.data.text}
              className="mb-3"
              onChange={(e) =>
                handleInputChange(index, "data", {
                  ...question.data,
                  text: e.target.value,
                })
              }
            />
            {question.data.blanks.map((blank, blankIndex) => (
              <InputGroup key={blankIndex} className="mb-3">
                <Form.Control
                  value={blank}
                  onChange={(e) => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.blanks[blankIndex] =
                      e.target.value;
                    setLocalQuestions(updatedQuestions);
                  }}
                />
                <Trash
                  style={{ color: "red", margin: "5px 0 0 10px" }}
                  size={15}
                  onClick={() => {
                    const updatedQuestions = [...localQuestions];
                    updatedQuestions[index].data.blanks.splice(blankIndex, 1);
                    setLocalQuestions(updatedQuestions);
                  }}
                />
              </InputGroup>
            ))}
            <Button
              variant="link"
              onClick={() => {
                const updatedQuestions = [...localQuestions];
                updatedQuestions[index].data.blanks.push("");
                setLocalQuestions(updatedQuestions);
              }}
            >
              + Add Blank
            </Button>
          </Form.Group>
        );
      default:
        return null;
    }
  };
  const handleEditQuestion = (question) => {
    dispatch(updateQuestionAction({ ...question, checkedBy: user?.username }));
  };
  const handleDeleteQuestion = (id) => {
    dispatch(deleteQuestionAction(id));
  };

  return (
    <Fragment>
      <Container style={{ marginTop: "20px" }}>
        {localQuestions.map((question, index) => (
          <div key={index} className="mb-4" style={{ position: "relative" }}>
            <Trash
              style={{
                position: "absolute",
                right: "15",
                top: 0,
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => handleDeleteQuestion(question._id)}
              size={"18px"}
            />
            {question?.checkedBy ? (
              <span
                style={{
                  position: "absolute",
                  right: 15,
                  top: 30,
                  color: "green",
                  cursor: "pointer",
                }}
              >
                Checked by {question?.checkedBy}
                <Check2Circle
                  style={{
                    color: "green",
                  }}
                  onClick={() => handleDeleteQuestion(question._id)}
                  size={"18px"}
                />
              </span>
            ) : (
              <span
                style={{
                  position: "absolute",
                  right: 15,
                  top: 30,
                  color: "gray",
                  cursor: "pointer",
                }}
              >
                Not checked {question?.checkedBy}
              </span>
            )}
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your question here"
                value={question.title} // Assuming `question.title` holds the question text
                onChange={(e) => {
                  const updatedQuestions = [...localQuestions];
                  updatedQuestions[index].title = e.target.value;
                  setLocalQuestions(updatedQuestions);
                }}
                style={{
                  border: "none", // Removes border
                  boxShadow: "none", // Removes focus shadow
                  padding: 0,
                  color: "black",
                  fontSize: "15px",
                  fontWeight: 600,
                  backgroundColor: "#F1F5F9",
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Enter your question description here"
                value={question.desc} // Assuming `question.description` holds the question text
                onChange={(e) => {
                  const updatedQuestions = [...localQuestions];
                  updatedQuestions[index].desc = e.target.value;
                  setLocalQuestions(updatedQuestions);
                }}
                style={{
                  border: "none", // Removes border
                  boxShadow: "none", // Removes focus shadow
                  padding: 0,
                  backgroundColor: "#F1F5F9",
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mark</Form.Label>
              <Form.Control
                type="number"
                value={question.mark}
                className="mb-3"
                onChange={(e) =>
                  handleInputChange(index, "mark", e.target.value)
                }
              />
            </Form.Group>

            {renderQuestionInputs(question, index)}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                borderBottom: "0.5px #A8A8A8 solid",
              }}
            >
              <Button
                variant="link"
                style={{ textDecoration: "none" }}
                onClick={() => handleEditQuestion(question)}
              >
                Save
              </Button>
            </div>
          </div>
        ))}
      </Container>
      {!show ? (
        <div>
          <Form.Group className="mb-3">
            <Form.Label>Add Question</Form.Label>
            <Row>
              <Col xs={4}>
                <Form.Select
                  aria-label="Default select example"
                  id="type"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="multi-choice">Multiple choice</option>
                  <option value="checkbox">CheckBox</option>
                  <option value="true-false">True/false</option>
                  <option value="matching">Matching</option>
                  <option value="fill-in-the-blank">Fill in the Blank</option>
                  <option value="order-the-phrase">Order The Phrase</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>

          <Button
            variant="link"
            style={{ textDecoration: "none" }}
            onClick={() => setShow(true)}
          >
            + Add New Question
          </Button>
        </div>
      ) : (
        <AddQuestion setShow={setShow} type={selectedType} />
      )}
    </Fragment>
  );
};

export default Questions;
