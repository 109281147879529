import { v4 as uuid } from 'uuid';

export const InvoicingData = [
  {
    id: uuid(),
    client_name: 'John Doe',
    pack_name: 'Basic Pack',
    price: 29.99,
    created_at: '2024-01-30 08:30:00',
    status: 'paid',
    invoicing_number: '12',
    email: 'john.doe@example.com',
    phone_number: '+1234567890',
    adress: "Gabes"
  },
  {
    id: uuid(),
    client_name: 'Jane Smith',
    pack_name: 'Premium Pack',
    price: 49.99,
    created_at: '2024-01-29 10:15:00',
    status: 'paid',
    invoicing_number: '13',
    email: 'jane.smith@example.com',
    phone_number: '+0987654321',
    adress: "Gabes"
  },
  {
    id: uuid(),
    client_name: 'Bob Johnson',
    pack_name: 'Standard Pack',
    price: 39.99,
    created_at: '2024-01-31 12:45:00',
    status: 'paid',
    invoicing_number: '14',
    email: 'bob.johnson@example.com',
    phone_number: '+1122334455',
    adress: "Gabes"
  },
  {
    id: uuid(),
    client_name: 'Alice White',
    pack_name: 'Deluxe Pack',
    price: 79.99,
    created_at: '2024-01-31 15:00:00',
    status: 'unpaid',
    invoicing_number: '15',
    email: 'alice.white@example.com',
    phone_number: '+9988776655',
    adress: "Gabes"
  }
  // Add more client data as needed
];

export default InvoicingData;
