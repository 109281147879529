import { Button, Form, Image, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { createUserAction, editUserAction, fetchUserAction } from 'actions/users';
import { useSelector } from 'react-redux';




const AddNewStudent = ({ showOffcanvas, setShowOffcanvas, userId, setUserId }) => {
    const { loading } = useSelector((state) => state.auth);
    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",

    });
    const [img, setImg] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png");
    //cloudinary
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;

        widgetRef.current = cloudinaryRef.current.createUploadWidget(
            {
                cloudName: "dtwkikmuy",
                uploadPreset: "wuraurta",
            },
            (error, result) => {
                if (result.info.secure_url) {

                    setImg(result.info.secure_url)

                }
            }
        );
    }, []);

    const { user: userEdited } = useSelector(state => state.users)
    const dispatch = useDispatch();

    const handleClose = () => {
        setUser({
            username: "",
            email: "",
            password: "",
        })
        setShowOffcanvas(false)
        setUserId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddReader = async (e) => {
        if (userId) {
            dispatch(editUserAction({ ...user, img: img }, handleClose, setUserId))
        } else {
            dispatch(createUserAction({ ...user, img: img }, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (userId) {
            dispatch(fetchUserAction(userId))
        }
    }, [dispatch, userId])
    useEffect(() => {
        if (userEdited?.id) {
            setUser({
                username: userEdited.username,
                email: userEdited.email,
                id: userEdited.id,

            })
            setImg(
                userEdited.img
            )
        }
    }, [userEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Add New Reader
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{userId ? 'Update Reader' : 'Add New Reader'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Image  */}
                        <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '100px', 
                                        height: '100px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {userId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group>
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Reader Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Reader Name"
                                id="username"
                                required
                                value={user.username}
                                onChange={(e) =>
                                    setUser({ ...user, username: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Reader Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Reader Email"
                                id="email"
                                value={user.email}
                                required
                                onChange={(e) =>
                                    setUser({ ...user, email: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* Releasing date  */}
                        {/* <Form.Group className="mb-3">
                          
                                <Form.Label>Joined At</Form.Label>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <DatePicker
                                    selected={selectedDate} // Pass the selected date value here
                                    onChange={handleDateChange} 
                                    placeholderText="Click Here" 
                                    className="form-control me-2" 
                                    id="release_date"
                                />
                            </div>
                        </Form.Group> */}
                        {/* PassWord  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Reader Password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder="**********"
                                id="password"
                                required
                                value={user.password}
                                onChange={(e) =>
                                    setUser({ ...user, password: e.target.value })
                                }
                            />


                        </Form.Group>

                        {/* Category  */}
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Category Here"
                                id="category"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group> */}
                        {/* Discount  */}
                        {/*   <Form.Group className="mb-3">
                            <Form.Label>Discount</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Put de discount"
                                id="category-name"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group>
 */}

                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                disabled={loading}
                                onClick={(e) => handleAddReader(e)}>
                                {userId ? 'Update Reader' : 'Add New Reader'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewStudent;