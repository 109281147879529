// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    Image,
    Row,
    Col,
    Table,
    Button,
    Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux';

import { deleteShcoolAction, fetchAllShcoolsAction } from 'actions/shcools';

const ShcoolsList = ({ setShcoolId }) => {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const dispatch = useDispatch();
    const { shcools } = useSelector(state => state.shcools);
   
    

    useEffect(() => {
        dispatch(fetchAllShcoolsAction())
    }, [dispatch])


    const handleDeleteTeacher = (id) => {
        setTeacherToDelete(id);
        setShowDeleteModal(true);
    };

    const confirmDeleteTeacher = () => {
        if (teacherToDelete && confirmationMessage === 'DELETE SCHOOL') {
            dispatch(deleteShcoolAction(teacherToDelete));
            setTeacherToDelete(null);
            setShowDeleteModal(false);
            setConfirmationMessage('');
        }
    };
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [teacherToDelete, setTeacherToDelete] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn-icon btn btn-ghost btn-sm rounded-circle"
        >
            {children}
        </Link>
    ));

    const ActionMenu = ({ id, setShcoolId }) => {
        return (
            <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
                <Dropdown.Toggle as={CustomToggle}>
                    <MoreVertical size="15px" className="text-secondary" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Header>SETTINGS</Dropdown.Header>
                    <Dropdown.Item
                        onClick={() => setShcoolId(id)}
                        eventKey="1">
                        {' '}
                        <Edit size="15px" className="dropdown-item-icon" /> Edit
                    </Dropdown.Item>
                    {userData?.role == "admin" &&
                        <Dropdown.Item
                            onClick={() => handleDeleteTeacher(id)}
                            eventKey="2">
                            {' '}
                            <Trash
                                size="15px"
                                className="dropdown-item-icon"
                                variant="danger"
                                onClick={confirmDeleteTeacher}

                            /> Remove
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const columns = useMemo(
        () => [
            { accessor: 'id', Header: 'ID', show: true },
          
          
            {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ value }) => {
                    return value;
                }
            },
            {
                accessor: 'message',
                Header: 'Action',
                Cell: ({ row }) => {
                    return (
                        <div className="hstack gap-4">
                            <ActionMenu id={row.original.id} setShcoolId={setShcoolId} />
                        </div>
                    );
                }
            }
        ],
        []
    );
    const data = useMemo(() => shcools, [shcools]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        prepareRow,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                })
            }
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, globalFilter } = state;

    return (
        <Fragment>
            <div className=" overflow-hidden">
                <Row>
                    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
                        <GlobalFilter
                            filter={globalFilter}
                            setFilter={setGlobalFilter}
                            placeholder="Search Shcool"
                        />
                    </Col>
                </Row>
            </div>

            <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
            >
                <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this school?</p>
                    <h4 className="text-secondary" >you must write "DELETE SCHOOL" to confirm the deletion</h4>
                    <input
                        className="form-control"
                        rows="3"
                        placeholder="Enter confirmation message"
                        value={confirmationMessage}
                        onChange={(e) => setConfirmationMessage(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant={confirmationMessage !== 'DELETE SCHOOL' ? 'secondary' : 'danger'}
                        disabled={confirmationMessage !== 'DELETE SCHOOL'} onClick={confirmDeleteTeacher}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Pagination @ Footer */}
            <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
            />
        </Fragment>
    );
};

export default ShcoolsList;
