import { Button, Form, Image, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { createTeacherAction, editTeacherAction, fetchTeacherAction } from 'actions/teachers';
import { useSelector } from 'react-redux';



  
const AddNewTeacher = ({showOffcanvas, setShowOffcanvas, teacherId , setTeacherId }) => {
    const {teacher:teacherEdited}= useSelector(state => state.teachers)
    const { loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [teacher, setTeacher] = useState({
        username: "",
        email: "",
        password: "",
        description: "",
        study_level: "",
      });
      
      const [img, setImg] = useState("https://media.istockphoto.com/id/1221354035/vector/vector-image-teacher-icon.jpg?s=612x612&w=0&k=20&c=hnxW9k8I7uQAkeCIR-rTwkIs1np6iY_lv7MSoXqjqpY=");
          //cloudinary
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;

        widgetRef.current = cloudinaryRef.current.createUploadWidget(
            {
                cloudName: "dtwkikmuy",
                uploadPreset: "wuraurta",
            },
            (error, result) => {
                if (result.info.secure_url) {

                    setImg(result.info.secure_url)

                }
            }
        );
    }, []);
      const handleClose = () => {
        setTeacher({
            username: "",
            email: "",
            password: "",
            description: "",
            study_level: "",
          })
        setShowOffcanvas(false)
        setTeacherId(null)
    } 
    const handleShow = () => setShowOffcanvas(true);
    
      const handleAddTeacher = async (e) => {
        if(teacherId){
            dispatch(editTeacherAction({ ...teacher, img: img }, handleClose,setTeacherId)) 
            
        }else{
            dispatch(createTeacherAction({ ...teacher, img: img }, handleClose));
        }
        e.preventDefault();
      };
     useEffect(()=>{
        if (teacherId){
            dispatch(fetchTeacherAction(teacherId))
        } 
     },[dispatch,teacherId])
     useEffect(()=>{
        if(teacherEdited.id){
       setTeacher({
        username:teacherEdited.username,
        email:teacherEdited.email,
        id: teacherEdited.id,
        study_level: teacherEdited.study_level,
        description: teacherEdited.description
       })
       setImg(
        teacherEdited.img
   )
        } 
       
     },[teacherEdited])
        return (
            <Fragment>
                <Button variant="primary" onClick={handleShow}>
                    Add New Teacher
                </Button>

                <Offcanvas
                    show={showOffcanvas}
                    onHide={handleClose}
                    placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{teacherId? 'Update Teacher': 'Add New Teacher'}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                             {/* Image  */}
                        <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '130px', 
                                        height: '130px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {teacherId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group>
                            {/* Title  */}
                            <Form.Group className="mb-3">
                                <Form.Label>Teacher Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Teacher Name"
                                    id="username"
                                    required
                                    value={teacher.username }
                                    onChange={(e) =>
                                        setTeacher({ ...teacher, username: e.target.value })
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Field must contain a unique value
                                </Form.Text>
                            </Form.Group>
                            {/* author  */}
                            <Form.Group className="mb-3">
                                <Form.Label>Teacher Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="teacher Email"
                                    id="email"
                                    value={teacher.email}
                                    required
                                    onChange={(e) =>
                                        setTeacher({ ...teacher, email: e.target.value })
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Field must contain a unique value
                                </Form.Text>
                            </Form.Group>
                            {/* Releasing date  */}
                            {/* <Form.Group className="mb-3">
                          
                                <Form.Label>Joined At</Form.Label>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <DatePicker
                                    selected={selectedDate} // Pass the selected date value here
                                    onChange={handleDateChange} 
                                    placeholderText="Click Here" 
                                    className="form-control me-2" 
                                    id="release_date"
                                />
                            </div>
                        </Form.Group> */}
                            {/* Page number  */}
                            <Form.Group className="mb-3">
                                <Form.Label>Teacher Password</Form.Label>
                                <Form.Control
                                    type= 'password'
                                    placeholder="**********"
                                    id="password"
                                    required
                                    value={teacher.password}
                                    onChange={(e) =>
                                        setTeacher({ ...teacher, password: e.target.value })
                                    }
                                />
                             
                              
                            </Form.Group>
                            {/* Category  */}
                            {/* <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Category Here"
                                id="category"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group> */}
                            {/* Discount  */}
                            {/*   <Form.Group className="mb-3">
                            <Form.Label>Discount</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Put de discount"
                                id="category-name"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group>
 */}
                           <Form.Group className="mb-3">
                                <Form.Label>Teacher Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="teacher description"
                                    id="description"
                                    required
                                    value={teacher.description }
                                    onChange={(e) =>
                                        setTeacher({ ...teacher, description: e.target.value })
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Study Level</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Study Level"
                                    id="study_level"
                                    required
                                    value={teacher.study_level }
                                    onChange={(e) =>
                                        setTeacher({ ...teacher, study_level: e.target.value })
                                    }
                                />
                            </Form.Group>
                            <div className="mt-3">
                                <Button type="submit"
                                 variant="primary"
                                  id="add-new-event-btn"
                                  disabled={loading}
                                  onClick={(e) => handleAddTeacher(e)}>
                                    {teacherId? 'Update Teacher': 'Add New Teacher'}
                                </Button>

                                <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </div>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </Fragment>
        );
    };

    export default AddNewTeacher;