// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button, Modal } from 'react-bootstrap';
import ListReader from './ListReader';
import PresenceManagement from './PresenceManagement';
import QuizInSession from './QuizInSession';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchSessionAction, getReaderInSessionAction } from 'actions/sessions';
import { Bold, Video } from 'react-feather';
import { BookFill } from 'react-bootstrap-icons';
import { startVideoSessionAction, startWhiteBoardAction } from 'actions/onlineSessions';

// import sub custom components




const SessionDetails = () => {
	const location = useLocation();
	const [showModal, setShowModal] = useState(false);
	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get("session_id");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { session } = useSelector((state) => state.sessions);
	console.log(session)
	const { videoSession,whiteBoard } = useSelector((state) => state.onlineSessions);
	useEffect(() => {
		dispatch(fetchSessionAction(id));
	}, [dispatch, id]);
	const handleMySession = () => {
		navigate("/teacher-dashboard/sessions")
	};
	const { reader_in_session } = useSelector(state => state.reader_in_session);
	useEffect(() => {
		dispatch(getReaderInSessionAction(id))
	}, [dispatch, id]);
	const staticCodesArray = [
		'abcdefgh',
		'ijklmnop',
		'qrstuvwx',
		'yzabcdef',
		'ghijklmn',
		'opqrstuv',
		'wxyzabcd',
		'efghijkl',
		'mnopqrst',
		'uvwxyzab'
	];
	const handleSendWBCodes = () => {
		dispatch(startWhiteBoardAction(id))
	  };
	  const handleVideoSession = () => {
	
	 dispatch(startVideoSessionAction({id,navigate}))
		
	  };
	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Session Details</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Teacher Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>My Sessions</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
					<Col className="d-flex justify-content-between">
						{session.location === "online" &&
							<div>
								<Button variant="primary" style={{ marginBottom: "15px" }} onClick={() => setShowModal(true)}>
									Start Online Session
								</Button>
							</div>
						}
						<div>
							<Button variant="primary" style={{ marginBottom: "15px" }} onClick={() => handleMySession()}>
								Back To My Sessions
							</Button>
						</div>
					</Col>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											List of Readers
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
											Presence Management
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											Quizs In Session Details
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<ListReader />
									</Tab.Pane>
									<Tab.Pane eventKey="approved" className="pb-4">
										<PresenceManagement />
									</Tab.Pane>
									<Tab.Pane eventKey="pending" className="pb-4">
										<QuizInSession />
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Start Online Session</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to start video class?</p>
					<Button style={{width:"40%", marginBottom:"10%"}} onClick={() =>(handleVideoSession())}>
						
						Video Class
						<Video size={"15px"} style={{marginLeft:"5%"}} />
					</Button>
					<p>Are you sure you want to start white board?</p>
					<Button style={{width:"40%"}} onClick={() =>(handleSendWBCodes())}>
						White Board
						<BookFill size={"15px"} style={{marginLeft:"5%"}}/>
					</Button>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default SessionDetails;
