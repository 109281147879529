import { JaaSMeeting } from '@jitsi/react-sdk';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function VideoMeeting() {
    const { user } = useSelector((state) => state.auth);
 
    const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const roomName = searchParams.get("session_name");
    const appId = searchParams.get("app_id");
    const domain = "meet.jit.si";

   
  
  

    const customSpinner = () => {
        return (
            <Spinner animation="border" variant="primary" role="status">
            </Spinner>
        );
    };

    const configOverwrite = {
        disableThirdPartyRequests: true,
        disableLocalVideoFlip: true,
        backgroundAlpha: 0.5,
        startAudioMuted: 10,
        startVideoMuted: 10,
        prejoinPageEnabled: false,

        disableInviteFunctions: true, 
        hideConferenceSubject: true, 


        // breakoutRooms: {
        // hideAddRoomButton: true,
        // hideAutoAssignButton: true,
        // hideJoinRoomButton: true,
        // hideModeratorSettingsTab:true,
        // hideMoreActionsButton:true,
        // hideMuteAllButton:true
        // },
        whiteboard: {
                enabled: true,
                collabServerBaseUrl: 'https://excalidraw-backend.example.com'
            }
    };
    return (
        <div style={{ height: "83vh", display: "grid", flexDirection: "column" }}>
            <JaaSMeeting
                appId={appId}
                spinner={customSpinner}
                domain={domain}
                roomName={roomName}
                configOverwrite={configOverwrite}
                
                interfaceConfigOverwrite ={{
                    SHOW_BRAND_WATERMARK: false,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    SHOW_JITSI_WATERMARK: false,
                }}
                userInfo={{
                    displayName: user.username,     
                    
                }}
                
                
            />
        </div>
    );
}

export default VideoMeeting;
