// import media files

// import avatar images
import Avatar1 from "assets/images/avatar/avatar-1.jpg";
import Avatar2 from "assets/images/avatar/avatar-2.jpg";
import Avatar3 from "assets/images/avatar/avatar-3.jpg";
import Avatar4 from "assets/images/avatar/avatar-4.jpg";
import Avatar5 from "assets/images/avatar/avatar-5.jpg";
import Avatar6 from "assets/images/avatar/avatar-6.jpg";
import Avatar7 from "assets/images/avatar/avatar-7.jpg";
import Avatar9 from "assets/images/avatar/avatar-9.jpg";
import Avatar10 from "assets/images/avatar/avatar-10.jpg";
import BookAvatar from "assets/images/avatar/book-avatar.png";

// import courses images
import LaravelCourse from "assets/images/course/course-laravel.jpg";
import GatsbyCourse from "assets/images/course/course-gatsby.jpg";
import JavaScriptCourse from "assets/images/course/course-javascript.jpg";
import NodeCourse from "assets/images/course/course-node.jpg";
import ReactCourse from "assets/images/course/course-react.jpg";
import AngularCourse from "assets/images/course/course-angular.jpg";
import GraphQLCourse from "assets/images/course/course-graphql.jpg";
import HTMLCourse from "assets/images/course/course-html.jpg";

export const allcourses = [
  {
    id: 1,
    image: GatsbyCourse,
    title: "Revolutionize how you build the web",
    date_added: "Added on 7 July, 2020",
    instructor_name: "Jenny Wilson",
    instructor_image: Avatar7,
    status: "Pending",
    action: 2,
    level: "Beginner",
    duration: "1h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 2,
    image: GraphQLCourse,
    title: "Guide to Static Sites with Gatsby",
    date_added: "Added on 6 July, 2021",
    instructor_name: "Brooklyn Simmons",
    instructor_image: Avatar6,
    status: "Pending",
    action: 2,
    level: "Beginner",
    duration: "1h 46m",
    rating: 4.0,
    ratingby: 7800,
  },
  {
    id: 3,
    image: HTMLCourse,
    title: "The Modern HTML Courses",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Miston Wilson",
    instructor_image: Avatar5,
    status: "Pending",
    action: 2,
    level: "Beginner",
    duration: "1h 46m",
    rating: 4.0,
    ratingby: 8245,
  },
  {
    id: 4,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar1,
    status: "Live",
    action: 1,
    level: "Beginner",
    duration: "1h 46m",
    rating: 4.0,
    ratingby: 3245,
  },
  {
    id: 5,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
  },
  {
    id: 6,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "3h 56m",
    rating: 4.0,
    ratingby: 5300,
  },
  {
    id: 7,
    image: ReactCourse,
    title: "Get Start with React",
    date_added: "Added on 4 July, 2021",
    instructor_name: "April Noms",
    instructor_image: Avatar2,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 8,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
    level: "Intermediate",
    duration: "1h 30m",
    rating: 4.0,
    ratingby: 5568,
  },
  {
    id: 9,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 30m",
    rating: 4.0,
    ratingby: 6245,
  },
  {
    id: 10,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 30m",
    rating: 4.0,
    ratingby: 6245,
  },
  {
    id: 11,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar10,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "1h 30m",
    rating: 4.0,
    ratingby: 5568,
  },
  {
    id: 12,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 13,
    image: ReactCourse,
    title: "Get Start with React",
    date_added: "Added on 4 July, 2021",
    instructor_name: "April Noms",
    instructor_image: Avatar2,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 14,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 15,
    image: GatsbyCourse,
    title: "Revolutionize how you build the web",
    date_added: "Added on 7 July, 2020",
    instructor_name: "Jenny Wilson",
    instructor_image: Avatar7,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 16,
    image: GraphQLCourse,
    title: "Guide to Static Sites with Gatsby",
    date_added: "Added on 6 July, 2021",
    instructor_name: "Brooklyn Simmons",
    instructor_image: Avatar6,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 17,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 18,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 19,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
  {
    id: 20,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar10,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 46m",
    rating: 4.0,
    ratingby: 9300,
  },
];
export const allbookstest = [
  {
    id: 1,
    img: BookAvatar,
    title: "Revolutionize how you build the web",
    release_date: "7 July, 2020",
    author: "Jenny Wilson",
    author_img: Avatar7,
    status: "Pending",
    action: 2,
    level: "Beginner",
    duration: "1h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 2,
    img: BookAvatar,
    title: "Guide to Static Sites with Gatsby",
    release_date: "6 July, 2021",
    author: "Brooklyn Simmons",
    author_img: Avatar6,
    status: "Pending",
    action: 1,
    level: "Beginner",
    duration: "1h 46m",
    category: "test",
    page_number: 7800,
  },
  {
    id: 3,
    img: BookAvatar,
    title: "The Modern HTML Courses",
    release_date: "5 July, 2021",
    author: "Miston Wilson",
    author_img: Avatar5,
    status: "Pending",
    action: 2,
    level: "Beginner",
    duration: "1h 46m",
    category: "test",
    page_number: 8245,
  },
  {
    id: 4,
    img: BookAvatar,
    title: "Courses JavaScript Heading Title",
    release_date: "5 July, 2021",
    author: "Guy Hawkins",
    author_img: Avatar1,
    status: "Live",
    action: 1,
    level: "Beginner",
    duration: "1h 46m",
    category: "test",
    page_number: 8245,
  },
  {
    id: 5,
    img: BookAvatar,
    title: "Get Start with Node Heading Title",
    release_date: "5 July, 2021",
    author: "Sina Ray",
    author_img: Avatar3,
    status: "Live",
    action: 1,
    category: "test",
    page_number: 8245,
  },
  {
    id: 6,
    img: BookAvatar,
    title: "Get Start with Laravel",
    release_date: "5 July, 2021",
    author: "Sobo Rikhan",
    author_img: Avatar9,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "3h 56m",
    category: "test",
    page_number: 5300,
  },
  {
    id: 7,
    img: BookAvatar,
    title: "Get Start with React",
    release_date: "4 July, 2021",
    author: "April Noms",
    author_img: Avatar2,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 8,
    img: BookAvatar,
    title: "Get Start with Angular",
    release_date: "3 July, 2021",
    author: "Jacob Jones",
    author_img: Avatar4,
    status: "Pending",
    action: 2,
    level: "Intermediate",
    duration: "1h 30m",
    category: "test",
    page_number: 5568,
  },
  {
    id: 9,
    img: BookAvatar,
    title: "Get Start with Laravel",
    release_date: "5 July, 2021",
    author: "Sobo Rikhan",
    author_img: Avatar9,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 30m",
    category: "test",
    page_number: 6245,
  },
  {
    id: 10,
    img: BookAvatar,
    title: "Get Start with Node Heading Title",
    release_date: "5 July, 2021",
    author: "Sina Ray",
    author_img: Avatar3,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 30m",
    category: "test",
    page_number: 6245,
  },
  {
    id: 11,
    img: BookAvatar,
    title: "Courses JavaScript Heading Title",
    release_date: "5 July, 2021",
    author: "Guy Hawkins",
    author_img: Avatar10,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "1h 30m",
    category: "test",
    page_number: 5568,
  },
  {
    id: 12,
    img: BookAvatar,
    title: "Get Start with Laravel",
    release_date: "5 July, 2021",
    author: "Sobo Rikhan",
    author_img: Avatar9,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 13,
    img: BookAvatar,
    title: "Get Start with React",
    release_date: "4 July, 2021",
    author: "April Noms",
    author_img: Avatar2,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 14,
    img: BookAvatar,
    title: "Get Start with Angular",
    release_date: "3 July, 2021",
    author: "Jacob Jones",
    author_img: Avatar4,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 15,
    img: BookAvatar,
    title: "Revolutionize how you build the web",
    release_date: "7 July, 2020",
    author: "Jenny Wilson",
    author_img: Avatar7,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 16,
    img: BookAvatar,
    title: "Guide to Static Sites with Gatsby",
    release_date: "6 July, 2021",
    author: "Brooklyn Simmons",
    author_img: Avatar6,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 17,
    img: BookAvatar,
    title: "Get Start with Angular",
    release_date: "3 July, 2021",
    author: "Jacob Jones",
    author_img: Avatar4,
    status: "Pending",
    action: 2,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 18,
    img: BookAvatar,
    title: "Get Start with Laravel",
    release_date: "5 July, 2021",
    author: "Sobo Rikhan",
    author_img: Avatar9,
    status: "Live",
    action: 1,
    level: "Advance",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 19,
    img: BookAvatar,
    title: "Get Start with Node Heading Title",
    release_date: "5 July, 2021",
    author: "Sina Ray",
    author_img: Avatar3,
    action: 1,
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
  {
    id: 20,
    img: BookAvatar,
    title: "Courses JavaScript Heading Title",
    release_date: "5 July, 2021",
    author: "Guy Hawkins",
    author_img: Avatar10,
    status: "Live",
    action: 1,
    level: "Intermediate",
    duration: "2h 46m",
    category: "test",
    page_number: 9300,
  },
];

export const allapprovedcourses = [
  {
    id: 4,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar1,
    status: "Live",
    action: 1,
  },
  {
    id: 5,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
  },
  {
    id: 6,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
  },
  {
    id: 7,
    image: ReactCourse,
    title: "Get Start with React",
    date_added: "Added on 4 July, 2021",
    instructor_name: "April Noms",
    instructor_image: Avatar2,
    status: "Live",
    action: 1,
  },
  {
    id: 9,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
  },
  {
    id: 10,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
  },
  {
    id: 11,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar10,
    status: "Live",
    action: 1,
  },
  {
    id: 12,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
  },
  {
    id: 13,
    image: ReactCourse,
    title: "Get Start with React",
    date_added: "Added on 4 July, 2021",
    instructor_name: "April Noms",
    instructor_image: Avatar2,
    status: "Live",
    action: 1,
  },
  {
    id: 18,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Live",
    action: 1,
  },
  {
    id: 19,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Live",
    action: 1,
  },
  {
    id: 20,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar10,
    status: "Live",
    action: 1,
  },
];

export const allpendingcourses = [
  {
    id: 1,
    image: GatsbyCourse,
    title: "Revolutionize how you build the web",
    date_added: "Added on 7 July, 2020",
    instructor_name: "Jenny Wilson",
    instructor_image: Avatar7,
    status: "Pending",
    action: 2,
  },
  {
    id: 2,
    image: GraphQLCourse,
    title: "Guide to Static Sites with Gatsby",
    date_added: "Added on 6 July, 2021",
    instructor_name: "Brooklyn Simmons",
    instructor_image: Avatar6,
    status: "Pending",
    action: 2,
  },
  {
    id: 3,
    image: HTMLCourse,
    title: "The Modern HTML Courses",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Miston Wilson",
    instructor_image: Avatar5,
    status: "Pending",
    action: 2,
  },
  {
    id: 8,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
  },
  {
    id: 14,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
  },
  {
    id: 15,
    image: GatsbyCourse,
    title: "Revolutionize how you build the web",
    date_added: "Added on 7 July, 2020",
    instructor_name: "Jenny Wilson",
    instructor_image: Avatar7,
    status: "Pending",
    action: 2,
  },
  {
    id: 16,
    image: GraphQLCourse,
    title: "Guide to Static Sites with Gatsby",
    date_added: "Added on 6 July, 2021",
    instructor_name: "Brooklyn Simmons",
    instructor_image: Avatar6,
    status: "Pending",
    action: 2,
  },
  {
    id: 17,
    image: AngularCourse,
    title: "Get Start with Angular",
    date_added: "Added on 3 July, 2021",
    instructor_name: "Jacob Jones",
    instructor_image: Avatar4,
    status: "Pending",
    action: 2,
  },
  {
    id: 18,
    image: LaravelCourse,
    title: "Get Start with Laravel",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sobo Rikhan",
    instructor_image: Avatar9,
    status: "Pending",
    action: 1,
  },
  {
    id: 19,
    image: NodeCourse,
    title: "Get Start with Node Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Sina Ray",
    instructor_image: Avatar3,
    status: "Pending",
    action: 1,
  },
  {
    id: 20,
    image: JavaScriptCourse,
    title: "Courses JavaScript Heading Title",
    date_added: "Added on 5 July, 2021",
    instructor_name: "Guy Hawkins",
    instructor_image: Avatar10,
    status: "Pending",
    action: 1,
  },
];
export const AllCoursesData = [
  allcourses,
  allapprovedcourses,
  allpendingcourses,
  allbookstest,
];
export const questions = [
  {
    title: "Choose the correct answers about hobbies",
    description: "Select all the correct options related to hobbies.",
    mark: 20,
    type: "checkbox",
    data: [
      {
        _id: 1,
        content: "Reading",
        correct: "true",
      },
      {
        _id: 2,
        content: "Swimming",
        correct: "true",
      },
      {
        _id: 3,
        content: "Cooking",
        correct: "true",
      },
      {
        _id: 4,
        content: "Driving",
        correct: "false",
      },
    ],
    covredPoints: [
      {
        _id: "1",
        title: "Vocabulary related to hobbies",
      },
      {
        _id: "2",
        title: "Understanding common activities",
      },
    ],
  },
  {
    title: "Select the correct sentence",
    category: "Grammar",
    description: "Choose the grammatically correct sentence.",
    mark: 20,
    type: "multi-choice",
    data: [
      {
        _id: 1,
        content: "She don't like apples.",
        correct: "false",
      },
      {
        _id: 2,
        content: "He likes playing football.",
        correct: "true",
      },
      {
        _id: 3,
        content: "They enjoys watching movies.",
        correct: "false",
      },
      {
        _id: 4,
        content: "I am go to school every day.",
        correct: "false",
      },
    ],
    covredPoints: [
      {
        _id: "1",
        title: "Correct use of simple present tense",
      },
      {
        _id: "2",
        title: "Understanding subject-verb agreement",
      },
    ],
  },
  {
    type: "true-false",
    title: "true or false",

    mark: 20,
    data: {
      correct: "true",
    },
    covredPoints: [],
  },
  {
    type: "order-the-phrase",
    title: "order",

    mark: 20,
    data: ["hello   ", "my", "name", "tazza"],
    covredPoints: [],
  },
  {
    type: "matching",

    title: "match",

    mark: 20,
    data: [
      {
        left: "1+a",
        right: "1+a",
      },
      {
        left: "2+b",
        right: "2+b",
      },
      {
        left: "3+c",
        right: "3+c",
      },
    ],
    covredPoints: [],
  },
  {
    type: "fill-in-the-blank",

    title: "fill",

    mark: 20,
    data: {
      text: "the###isblue###and###",
      blanks: ["color", "one", "two"],
    },
    covredPoints: [],
  },
];

export default AllCoursesData;
