// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Image,
	Row,
	Col,
	Table,
    Button,
} from 'react-bootstrap';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

// import utility file

// import data files

import DotBadge from 'components/elements/bootstrap/DotBadge';
import { useSelector } from 'react-redux';
import {  fetchAllTeachersAction } from 'actions/teachers';
import { useDispatch } from 'react-redux';
import { fetchAllFollowedSessionsAction } from 'actions/followedSession';

const ListOfSessionByReader = () => {
	const dispatch = useDispatch();
    const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const userId = searchParams.get("user_id");
    const userName = searchParams.get("name");
    const { followedsessions } = useSelector(state => state.followedSession);
    useEffect(() => {
        dispatch(fetchAllFollowedSessionsAction())

    }, [dispatch]);
    const handleListReader = () => {
		navigate("/books-management/choose_reader")
	};

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));



	const columns = useMemo(
		() => [
			{ accessor: 'session_id', Header: 'ID', show: true},
		
			{
				accessor: 'session_name',
				Header: 'Session Title',
				Cell: ({ value }) => {
					return value;
				}
			},
		],
		[]
	);
	const data = useMemo(() => followedsessions.filter(session => session?.user_id === parseInt(userId)), [followedsessions, userId]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div>
                <h2>
                    Sessions For <span style={{color:"red"}}> {userName} </span>
                </h2>
            </div>
            <Col className="d-flex justify-content-end">
						<div>

							<Button variant="primary" style={{ marginTop: "20px" }} onClick={() => handleListReader()}>
								Back To List of Readers
							</Button>
						</div>
					</Col>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Reader"
						/>
					</Col>
				</Row>
			</div>

			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default ListOfSessionByReader;
