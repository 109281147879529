// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Nav, Tab, Container } from "react-bootstrap";

// import custom components
import PageHeadingBriefinfo from "components/marketing/common/page-headings/PageHeadingBriefinfo";

// import sub components
import PopularInstructorCard from "./PopularInstructorCard";
import CourseCard from "../CourseCard";

// import data files
import { AllCoursesData } from "data/slider/AllCoursesData";
import { InstructorData } from "data/users/InstructorData";
import { useDispatch } from "react-redux";
import { fetchAllPacksAction } from "actions/packs";
import { useSelector } from "react-redux";
import PackCard from "../PackCard";

const CourseCategory = () => {
  const dispatch = useDispatch();
  const { packs } = useSelector((state) => state.packs);
  const ages = [...new Set(packs.map((item) => item.age))];
  const [filter, setFilter] = useState("");
/*   useEffect(() => {
    dispatch(fetchAllPacksAction());
    setFilter(ages[0]);
  }, [dispatch, filter]); */

  return (
    <Fragment>
      {/* Page header */}
      <PageHeadingBriefinfo
        pagetitle="JavaScript Courses"
        briefinfo="6,979,934 students are learning JavaScript."
      />

      <section className="py-6">
        <Container>
          <Row className="mb-6">
            <Col md={12}>
              <Tab.Container defaultActiveKey="all">
                <Nav className="nav-lb-tab">
                  <Nav.Item className="ms-0">
                    <Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
                      ALL
                    </Nav.Link>
                  </Nav.Item>
                  {ages.map((age, index) => (
                    <Nav.Item className="ms-0" key={index}>
                      <Nav.Link
                        eventKey={age}
                        className="mb-sm-3 mb-md-0"
                        style={{ textTransform: "uppercase" }}
                      >
                        {age}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                {ages.map((age, index) => (
                  <Tab.Content key={index}>
                    <Tab.Pane eventKey={age} className="pb-4 p-4 ps-0 pe-0">
                      {/* trending courses started */}
                      <Row>
                        {packs
                          .filter((e) => e.age === age)
                          .map((item, index) => (
                            <Col lg={3} md={6} sm={12} key={index}>
                              <PackCard item={item} />
                            </Col>
                          ))}
                      </Row>
                      {/* end of trending courses */}
                    </Tab.Pane>
                  </Tab.Content>
                ))}
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4 p-4 ps-0 pe-0">
                    {/* trending courses started */}
                    <Row>
                      {packs.map((item, index) => (
                        <Col lg={3} md={6} sm={12} key={index}>
                          <PackCard item={item} />
                        </Col>
                      ))}
                    </Row>
                    {/* end of trending courses */}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>

          {/* Popular Instructors start */}
          {/* <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mb-5">
                <h2 className="mb-1">Popular Instructors</h2>
                <p className="mb-0">
                  Popular instructor in JavaScript Courses.
                </p>
              </div>
            </Col>
          </Row> */}
          {/* <Row className="mb-6">
            {InstructorData.filter(function (datasource) {
              return datasource.students > 26000;
            }).map((item, index) => (
              <Col lg={3} md={6} sm={12} key={index}>
                <PopularInstructorCard item={item} />
              </Col>
            ))}
          </Row> */}
          {/* end of Popular Instructors */}

          {/* Free JavaScript lessons start */}
          {/* <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mb-5">
                <h2 className="mb-1">Free JavaScript lessons</h2>
                <p className="mb-0">Bite-sized learning in minutes</p>
              </div>
            </Col>
          </Row>
          <Row className="mb-6">
            {AllCoursesData.filter(function (datasource) {
              return datasource.category === "javascript";
            })
              .slice(0, 4)
              .map((item, index) => (
                <Col lg={3} md={6} sm={12} key={index}>
                  <CourseCard item={item} free />
                </Col>
              ))}
          </Row> */}
          {/* end of Free JavaScript lessons */}

          {/* all javaScript courses start */}
          {/* <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="mb-5">
                <h2 className="mb-1">All JavaScript courses</h2>
                <p className="mb-0">
                  Geeks instructors specialize in teaching the whole scope of
                  JavaScript—beginner to advanced. Whether you’re interested in
                  back-end development, or app and website building, Udemy has a
                  JavaScript course for you.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-6">
            {packs.map((item, index) => (
              <Col lg={3} md={6} sm={12} key={index}>
                <PackCard item={item} />
              </Col>
            ))}
          </Row> */}
          {/* end of all javaScript courses */}
        </Container>
      </section>
    </Fragment>
  );
};

export default CourseCategory;
