import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
// import { BrowserRouter as Router, Route,Switch, Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Breadcrumb } from "react-bootstrap";
// import sub custom components
import SessionsTable from "./SessionsTable";

const TeacherSessions = () => {
  const [sessionId, setSessionId] = useState(null);
  const [showSession, setShowSession] = useState(false);

  useEffect(() => {
    if (sessionId) setShowSession(true);
  }, [sessionId]);

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">My Sessions</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Teacher Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>My Sessions</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <SessionsTable setSessionId={setSessionId} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default TeacherSessions;
