import { Button, Form, Offcanvas } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { addQuizToSessionAction, fetchAllQuizsAction } from "actions/quiz";

import { useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
const AddQuiz = ({ showOffcanvas, setShowOffcanvas }) => {
  const { loading } = useSelector((state) => state.auth);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [assignQuiz, setAssignQuiz] = useState({
    quiz_token: "",
    release_date: new Date(),
  });

  const { quizs } = useSelector((state) => state.quizs);
  useEffect(() => {
    dispatch(fetchAllQuizsAction());
  }, [dispatch]);
  const options = [...quizs.map((item) => item)];
  const handleQuizSelection = (selected) => {
    if (selected[0]?._id)
      setAssignQuiz({ ...assignQuiz, quiz_token: selected[0]._id });
  };

  const handleClose = () => {
    setAssignQuiz({
      quiz_token: "",
    });
    setShowOffcanvas(false);
  };
  const handleShow = () => setShowOffcanvas(true);

  const handleAddQuiz = async (e) => {
    e.preventDefault();
    dispatch(
      addQuizToSessionAction({ ...assignQuiz, session_id: id }, handleClose)
    );
  };

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add Quiz
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add New Quiz To Session</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Quiz</Form.Label>
              <Typeahead
                id="autocomplete-example"
                labelKey="title"
                options={options}
                placeholder="Type to search quiz..."
                onChange={handleQuizSelection}
              />
              <Form.Text className="text-muted">
                Field must contain a unique value
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-2 event-date" controlId="eventStartDate">
              <Form.Label>Select Release Date</Form.Label>
              <Flatpickr 
                className="form-control"
                vlaue={""}
                options={{
                  dateFormat: "Y-m-d",
                  monthSelectorType: "dropdown",
                  yearSelectorType: "static",
                  static: true,
                }}
                onChange={(date) => {
                  setAssignQuiz({
                    ...assignQuiz,
                    release_date: new Date(date[0]),
                  });
                }}
              />
            </Form.Group>
            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
                id="add-new-event-btn"
                onClick={(e) => handleAddQuiz(e)}
              >
                Add Quiz
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddQuiz;
