import PackAvatar from 'assets/images/avatar/pack-avatar.png';

export const courses = [
	{
		id: 1,
		category: 'Workshop',
		slug: 'workshop',
		posts: 2,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 2,
		category: 'Course',
		slug: 'desgincourse',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 3,
		category: 'Company',
		slug: 'company',
		posts: 5,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 4,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 16,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 5,
		category: 'Business',
		slug: 'business',
		posts: 9,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 6,
		category: 'Technology',
		slug: 'technology',
		posts: 30,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 0,
		action: 1
	},
	{
		id: 7,
		category: 'Technology',
		slug: 'technology',
		posts: 15,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 8,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 17,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 9,
		category: 'Business',
		slug: 'business',
		posts: 16,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 10,
		category: 'Course',
		slug: 'desgincourse',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 11,
		category: 'Course',
		slug: 'desgincourse',
		posts: 1,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 12,
		category: 'Workshop',
		slug: 'workshop',
		posts: 6,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 13,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 14,
		category: 'Company',
		slug: 'company',
		posts: 1,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 15,
		category: 'Technology',
		slug: 'technology',
		posts: 6,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 16,
		category: 'Course',
		slug: 'desgincourse',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 0,
		action: 1
	},
	{
		id: 17,
		category: 'Technology',
		slug: 'technology',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 18,
		category: 'Business',
		slug: 'business',
		posts: 1,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 19,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 6,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 20,
		category: 'Workshop',
		slug: 'workshop',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 21,
		category: 'Course',
		slug: 'desgincourse',
		posts: 1,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 22,
		category: 'Company',
		slug: 'company',
		posts: 4,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 23,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 4,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 24,
		category: 'Workshop',
		slug: 'workshop',
		posts: 7,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 25,
		category: 'Technology',
		slug: 'technology',
		posts: 8,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 26,
		category: 'Business',
		slug: 'business',
		posts: 10,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 0,
		action: 1
	},
	{
		id: 27,
		category: 'Technology',
		slug: 'technology',
		posts: 4,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 28,
		category: 'Business',
		slug: 'business',
		posts: 5,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 29,
		category: 'Tutorial',
		slug: 'tutorial',
		posts: 3,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	},
	{
		id: 30,
		category: 'Workshop',
		slug: 'workshop',
		posts: 2,
		date_created: '16 Oct, 2020',
		date_updated: '16 Nov, 2020',
		status: 1,
		action: 1
	}
];
export const packs = [
	{
		id: 1,
		title: 'test',
		img : PackAvatar,
		level: 'B-2',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 2,
		title:'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 3,
		title: 'test',
		img : PackAvatar,
		level: 'B-2',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 4,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 5,
		title: 'test',
		img : PackAvatar,
		level: 'B-2',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 6,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 7,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 8,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 9,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 10,
		title:'test',
		img : PackAvatar,
		level: 'B-2',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 11,
		title:'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 12,
		title: 'test',
		img : PackAvatar,
		level: 'B-6',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 13,
		title: 'test',
		img : PackAvatar,
		level: 'B-3',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 14,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 15,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-6',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 16,
		title:'test',
		img : PackAvatar,
		level: 'B-3',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 17,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-3',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 18,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 19,
		title: 'test',
		img : PackAvatar,
		level: 'B-6',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 20,
		title: 'test',
		img : PackAvatar,
		level: 'B-3',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 21,
		title:'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 22,
		title: 'test',
		img : PackAvatar,
		level: 'B-4',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 23,
		title: 'test',
		img : PackAvatar,
		level: 'B-4',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 24,
		title: 'test',
		img : PackAvatar,
		level: 'B-7',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 25,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-8',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 26,
		title: 'test',
		img : PackAvatar,
		level: 'B-1',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 27,
		title: 'Tetest',
		img : PackAvatar,
		level: 'B-4',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 28,
		title: 'test',
		img : PackAvatar,
		level: 'B-5',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 29,
		title: 'test',
		img : PackAvatar,
		level: 'B-3',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	},
	{
		id: 30,
		title: 'test',
		img : PackAvatar,
		level: 'B-2',
		age: 'kids',
		book_number: '5',
		price:20,
		discount:4
	}
];
export const CoursesCategoryData = [courses, packs];
