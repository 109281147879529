import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Tab, Card, Breadcrumb } from "react-bootstrap";
// import sub custom components
import BooksTable from "./BooksTable";
import AddNewBook from "../AddNewBook";

const AllBooks = () => {
  const [bookId, setBookId] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (bookId) setShow(true);
  }, [bookId]);

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Books</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Books management</Breadcrumb.Item>
                <Breadcrumb.Item active>Books</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Col className="d-flex justify-content-end">
              <div>
                <AddNewBook
                  showBook={show}
                  setShowBook={setShow}
                  setBookId={setBookId}
                  bookId={bookId}
                />
              </div>
            </Col>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <BooksTable setBookId={setBookId} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default AllBooks;
