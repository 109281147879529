// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Col,
	Row,
	Dropdown,
	Table,
	Card,
	InputGroup,
	Modal,
	Button,
	ListGroup,
} from 'react-bootstrap';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { AvatarGroup, Avatar } from 'components/elements/bootstrap/Avatar';

// import data files
import ProjectsListData from 'data/dashboard/projects/ProjectsListData';
import ProjectTeamMembersData from 'data/dashboard/projects/ProjectTeamMembersData';

// import utility file
import { numberWithCommas, getStatusColor, reformatSearchDate, reformatDate } from 'helper/utils';
import InvoiceData from 'data/marketing/InvoiceData';
import InvoicingData from 'data/dashboard/projects/invoicingData';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAllInvoicesAction } from 'actions/invoicing';

const ProjectListTable = () => {
	const filterOptions = [
		{ value: 'paid', label: 'Paid' },
		{ value: 'unpaid', label: 'Unpaid' },
	];
	const dispatch = useDispatch();
	const { invoices } = useSelector((state) => state.followedPack)
	useEffect(() => {
		dispatch(fetchAllInvoicesAction())
	}, [dispatch])
	const [showModal, setShowModal] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const handleDetails = (invoice) => {
		setSelectedInvoice(invoice);
		setShowModal(true);
	};
	const handleClose = () => {
		setShowModal(false);
		setSelectedInvoice(null)
	};
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const ActionMenu = () => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<i className="fe fe-more-vertical text-muted"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>Settings</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						<i className="fe fe-edit dropdown-item-icon"></i>Edit Details
					</Dropdown.Item>
					<Dropdown.Item eventKey="2">
						<i className="fe fe-link dropdown-item-icon"></i>Copy project link
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-save dropdown-item-icon"></i>Save as Default
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-copy dropdown-item-icon"></i>Duplicate
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-layout dropdown-item-icon"></i>Convert to
						Template
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item eventKey="3">
						<i className="fe fe-upload dropdown-item-icon"></i>Import
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-printer dropdown-item-icon"></i>Export / Print
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-users dropdown-item-icon"></i>Move to another
						team
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item eventKey="3">
						<i className="fe fe-archive dropdown-item-icon"></i>Archive
					</Dropdown.Item>
					<Dropdown.Item eventKey="3">
						<i className="fe fe-trash dropdown-item-icon"></i>Delete Project
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'invoiceNumber',
				Header: 'Number',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<div className="ms-3">
								<h4 className="mb-0">
									<Link to={"/dashboard/invoice_details/" + row.original._id} className="text-inherit">
										{'#' + value}
									</Link>
								</h4>
							</div>
						</div>
					);
				}
			},
			{
				accessor: 'client_name',
				Header: 'Client Name',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<div>
								<Link to={"/dashboard/invoice_details/" + row.original._id} className="text-inherit">
									<h5 >
										{value}
									</h5>
								</Link>
							</div>
						</div>
					);
				}
			},

			{
				accessor: 'createdAt',
				Header: 'Creation date',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<div>
								<Link to={"/dashboard/invoice_details/" + row.original._id} className="text-inherit">
									<h5>
										{reformatSearchDate(value)}
									</h5>
								</Link>
							</div>
						</div>
					);
				}
			},
			{
				accessor: 'total',
				Header: 'Budget',
				Cell: ({ value, row }) => {
					return (
						
						<div className="d-flex align-items-center" >
							<div className="ms-3">
							<Link to={"/dashboard/invoice_details/"+row.original._id} className="text-inherit">
								<h5 className="mb-0">
									{value + row.original.currency}
								</h5>
								</Link>
							</div>
						</div>
					);
				}
			},
			// {
			// 	accessor: 'progress',
			// 	Header: 'Task Progress',
			// 	Cell: ({ value, row }) => {
			// 		return (
			// 			<div className="d-flex align-items-center">
			// 				<div className="me-2">
			// 					{' '}
			// 					<span>{value}%</span>
			// 				</div>
			// 				<ProgressBar
			// 					variant={getStatusColor(row.original.status)}
			// 					now={value}
			// 					className="flex-auto"
			// 					style={{ height: '6px' }}
			// 				/>
			// 			</div>
			// 		);
			// 	}
			// },

			{
				accessor: 'status',
				Header: 'Status',
				Cell: ({ value, row }) => {
					return (
						<Link to={"/dashboard/invoice_details/"+row.original._id} className="text-inherit">
						<span 
							className={`badge bg-light-${getStatusColor(
								value
							)} text-dark-${getStatusColor(value)}`}
						>
							{value}
						</span>
						</Link>
					);
				}
			},
			// {
			// 	accessor: 'team',
			// 	Header: 'Team',
			// 	Cell: ({ value }) => {
			// 		return (
			// 			<AvatarGroup>
			// 				{ProjectTeamMembersData.filter(function (dataSource) {
			// 					return (
			// 						dataSource.id === value[0] ||
			// 						dataSource.id === value[1] ||
			// 						dataSource.id === value[2]
			// 					);
			// 				}).map((member, index) => {
			// 					return (
			// 						<Avatar
			// 							size="md"
			// 							src={member.image}
			// 							type={`${member.image == null ? 'initial' : 'image'}`}
			// 							name={member.name}
			// 							className="rounded-circle"
			// 							imgtooltip
			// 							key={index}
			// 						/>
			// 					);
			// 				})}
			// 				<Avatar
			// 					size="md"
			// 					type="initial"
			// 					name={value.length - 3 + '+'}
			// 					variant="light"
			// 					className="rounded-circle text-dark"
			// 					showExact
			// 				/>
			// 			</AvatarGroup>
			// 		);
			// 	}
			// },
			// {
			// 	accessor: 'action',
			// 	Header: '',
			// 	Cell: () => {
			// 		return <ActionMenu />;
			// 	}
			// }
		],
		[]
	);

	const data = useMemo(() => {
		if (!selectedDate) {
			return invoices;
		}

		const formattedSelectedDate = reformatDate(selectedDate);

		const filteredData = invoices.filter(item => {
			const itemFormattedDate = reformatSearchDate(item.createdAt);
			return (
				itemFormattedDate >= formattedSelectedDate &&
				itemFormattedDate <= formattedSelectedDate
			);
		});

		return filteredData;
	}, [selectedDate]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<Row className="justify-content-md-between mb-4 mb-xl-0 ">
				<Col xl={2} lg={4} md={6} xs={12}>
					{/* search records */}
					<div className="mb-2 mb-lg-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search name"
						/>
					</div>
				</Col>
				<Col xxl={2} lg={2} md={6} xs={12}>
					<InputGroup style={{ display: 'flex', width: "110%", marginLeft: "-10%" }}>
						<input
							type="date"
							style={{
								border: '1px solid #DDDDDD',
								borderRadius: '0.25rem',
								padding: '0.375rem 0.75rem',
								fontSize: '16px',
								lineHeight: '1.5',
								color: '#64788B',
								backgroundColor: '#fff',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'right calc(0.375rem + 2px) center',
								backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
								appearance: 'none',
								marginLeft: '-10%',
							}}
							onChange={(e) => {
								const selectedDate = new Date(e.target.value);
								const formattedDate = selectedDate.toLocaleDateString('en-GB', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								});
								setSelectedDate(formattedDate);
							}}
						/>

					</InputGroup>
				</Col>

			</Row>

			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Body className="p-0">
							<div className="table-responsive border-0 overflow-y-hidden">
								<Table
									hover
									{...getTableProps()}
									className="text-nowrap table-centered"
								>
									<thead>
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page.map((row) => {
											prepareRow(row);
											return (
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => {
														return (
															<td
																{...cell.getCellProps()}
																className="align-middle"
															>
																{cell.render('Cell')}
															</td>
														);
													})}
												</tr>
											);
										})}
										{/* <tr>
											<td className="align-middle " colSpan="7">
												<div className="d-flex align-items-center">
													<Button
														variant="link"
														className="text-muted border border-2 rounded-3 card-dashed-hover p-0"
														onClick={onNewProject}
													>
														<div className="icon-shape icon-lg ">+</div>
													</Button>
													<div className="ms-3">
														<h4 className="mb-0">
															<Link to="#" className="text-inherit">
																New Project
															</Link>
														</h4>
													</div>
												</div>
											</td>
										</tr> */}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
					<Modal show={showModal} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title style={{ fontSize: "26px", color: "#64788B" }}>Receipt</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Card className="mt-4 mt-lg-0 mb-4">
								<Card.Header className="d-lg-flex" style={{ backgroundColor: "#F5F5F5" }}>
									<span className="text-body" style={{ fontSize: "15px", marginLeft: "38%" }}>invoice : </span>
									<h5> #{selectedInvoice?.invoicing_number}</h5>
								</Card.Header>
								<Card.Body className="p-0">
									<ListGroup variant="flush">
										<ListGroup.Item >
											<div className="d-flex align-items-center">
												<span className="text-body me-3">Status : </span>
												<h5 className="mb-0">
													<DotBadge bg={selectedInvoice?.status === 'paid' ? 'success' : 'danger'}>
														<span
															className={`badge bg-light-${getStatusColor(
																selectedInvoice?.status
															)} text-dark-${getStatusColor(selectedInvoice?.status)}`}
														>
															{selectedInvoice?.status}
														</span>
													</DotBadge>
												</h5>
											</div>
											<div className="d-flex align-items-center">
												<span className=" text-body">Created at : </span>
												<h5 style={{ marginLeft: "2%", marginTop: "7px" }} >{selectedInvoice?.created_at}</h5>
											</div>
										</ListGroup.Item>
										{/* <ListGroup.Item>
											<span className="text-body">Created by</span>
											<div className="d-flex mt-2">
												<div className="ms-2">
													<h5 className="mb-n1">Geeks Courses</h5>
													<small>Admin</small>
												</div>
											</div>
										</ListGroup.Item> */}
										<ListGroup.Item style={{ backgroundColor: "#F5F5F5" }}>
											<span className="text-body">Bill to :</span>
											<h5>{selectedInvoice?.client_name}</h5>
											<h5>{selectedInvoice?.email}</h5>
											<h5>{selectedInvoice?.phone_number}</h5>
											<h5>{selectedInvoice?.adress}</h5>
										</ListGroup.Item>
										<ListGroup.Item>
											<span className="text-body">Product : </span>
											<h5>{selectedInvoice?.pack_name}</h5>
										</ListGroup.Item>
										<ListGroup.Item className="bg-transparent">
											<span className="text-body">Total Price</span>
											<h5>{selectedInvoice?.price} Dt</h5>
										</ListGroup.Item>
									</ListGroup>
								</Card.Body>
							</Card>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							{/* Add additional buttons or actions here if needed */}
						</Modal.Footer>
					</Modal>
					{/* Pagination @ Footer */}
					<div className="mt-4">
						{' '}
						<Pagination
							previousPage={previousPage}
							pageCount={pageCount}
							pageIndex={pageIndex}
							gotoPage={gotoPage}
							nextPage={nextPage}
						/>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ProjectListTable;
