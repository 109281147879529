import { Button, Form,  Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect,  useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createShcoolAction, fetchOneShcoolAction, updateShcoolAction } from 'actions/shcools';




const AddNewShcool = ({ showOffcanvas, setShowOffcanvas, shcoolId, setShcoolId }) => {
    const { shcool: shcoolToEdit } = useSelector(state => state.shcools)
    const { loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [shcool, setShcool] = useState({
        name: "",
    });
    const handleClose = () => {
        setShcool({
            name: ""
        })
        setShowOffcanvas(false)
        setShcoolId(null)
    }
    const handleShow = () => setShowOffcanvas(true);

    const handleAddShcool = async (e) => {
        if (shcoolId) {
            dispatch(updateShcoolAction(shcool, shcoolId, handleClose))

        } else {
            dispatch(createShcoolAction(shcool, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (shcoolId) {
            dispatch(fetchOneShcoolAction(shcoolId))
        }
    }, [dispatch, shcoolId])
    useEffect(() => {
        if (shcoolToEdit.id) {
            setShcool({
                name: shcoolToEdit.name
            })

        }
    }, [shcoolToEdit])
    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Add New School
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{shcoolId ? 'Update School' : 'Add New School'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Shcool Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="School Name"
                                id="username"
                                required
                                value={shcool.name}
                                onChange={(e) =>
                                    setShcool({ name: e.target.value })
                                }
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group>
                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                disabled={loading}
                                onClick={(e) => handleAddShcool(e)}>
                                {shcoolId ? 'Update School' : 'Add New School'}
                            </Button>
                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewShcool;