// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button } from "react-bootstrap";
import ListReader from "./ListReader";
import PresenceManagement from "./PresenceManagement";
import QuizInSession from "./QuizInSession";
import { fetchTeacherAction } from "actions/teachers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSessionAction } from "actions/sessions";
import {
  addUserToSessionAction,
  removeUserFromSessionAction,
} from "actions/followedSession";
import AddUserToSession from "./AddUserToSession";
import { ToastContainer } from "react-toastify";

// import sub custom components

const OneSessionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const teacher_id = searchParams.get("teacher_id");
  const session_id = searchParams.get("session_id");
  const [userId, setUserId] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const { teacher } = useSelector((state) => state.teachers);
  const { session } = useSelector((state) => state.sessions);
  useEffect(() => {
    if (session_id) {
      dispatch(fetchSessionAction(session_id));
    }
  }, [dispatch, session_id]);
  useEffect(() => {
    if (teacher_id) {
      dispatch(fetchTeacherAction(teacher_id));
    }
  }, [dispatch, teacher_id]);

  const handleAddStudentToSession = () => {
    dispatch(
      addUserToSessionAction(
        { user_id: userId, token: session_id },
        setShowOffcanvas
      )
    );
  };
  const handleRemoveUserFromSession = (id) => {
    const confirmRemoval = window.confirm(
      "Are you sure you want to remove this user from the session?"
    );

    if (confirmRemoval) {
      dispatch(removeUserFromSessionAction({ token: session_id, user_id: id }));
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h2 className="mb-2 h4 fw-bold">
                Session {"  >  "} <span>{session?.name}</span>{" "}
              </h2>
              <h4 className="mb-1 h4 fw-bold">
                Teacher {"  >  "} <span>{teacher?.username}</span>{" "}
              </h4>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Teacher Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>My Sessions</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <Col className="d-flex justify-content-end">
            <div style={{ marginBottom: "20px" }}>
              <AddUserToSession
                showOffcanvas={showOffcanvas}
                setShowOffcanvas={setShowOffcanvas}
                setUserId={setUserId}
                handleAddStudentToSession={handleAddStudentToSession}
              />
            </div>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
                      List of Readers
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                      Presence Management
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
                      Quizs In Session Details
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <ListReader />
                  </Tab.Pane>
                  <Tab.Pane eventKey="approved" className="pb-4">
                    <PresenceManagement
                      handleRemoveUserFromSession={handleRemoveUserFromSession}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="pending" className="pb-4">
                    <QuizInSession />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default OneSessionDetails;
