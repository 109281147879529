import { PacksDetailsData } from "data/courses/BootstrapCoursesData";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css"; // Import Typeahead CSS
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBooksAction } from "actions/books";

const AutocompletePack = ({ selectedOption, setSelectedOption }) => {
  const dispatch = useDispatch();

  const { books } = useSelector((state) => state.books);
  useEffect(() => {
    dispatch(fetchAllBooksAction());
  }, [dispatch]);

  const handleSelection = (selected) => {
    setSelectedOption(selected);
  };

  // const options = [...books?.map((item) => item)];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="title"
        options={books}
        placeholder="Type to search..."
        selected={selectedOption}
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompletePack;
