// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Col, Row, Card, Button, Modal, Table } from "react-bootstrap";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getResultInSessionAction } from "actions/quiz";
import { Check, Delete, X } from "react-feather";

// import data files

const ReaderQuizs = () => {
  const [showModal, setShowModal] = useState();
  const [finalResult, setFinalResult] = useState();
  const [quizTitle, setQuizTitle] = useState();
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const user_id = searchParams.get("quiz_id");
  const teacher_id = searchParams.get("teacher_id");
  const dispatch = useDispatch();
  const { results } = useSelector((state) => state.quizs);
  console.log(finalResult);
  const { loading } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getResultInSessionAction(session_id, user_id));
  }, [dispatch, session_id, user_id]);

  const handleQuizResult = (result, title, quizToken) => {
    setFinalResult(result);
    setQuizTitle(title);
    setToken(quizToken);
    setShowModal(true);
  };

  const handleReader = () => {
    navigate(
      `/books-management/sessions_details/session?session_id=${session_id}&teacher_id=${teacher_id}`
    );
  };

  const columns = useMemo(
    () => [
      {
        accessor: "title",
        Header: "Title",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              <h5
                className="text-inherit"
                onClick={() =>
                  handleQuizResult(
                    row.original,
                    row.original.title,
                    row.original.token
                  )
                }
              >
                {value}
              </h5>
            </div>
          );
        },
      },
      {
        accessor: "completed",
        Header: "Status",
        Cell: ({ value, row }) => {
          let status;
          let variant;

          if (value === true) {
            status = "Completed";
            variant = "success";
          } else if (value === null || value === undefined) {
            status = "Incomplete";
            variant = "danger";
          }

          return (
            <div className="d-flex align-items-center">
              <h5 className={`mb-0 text-${variant}`}>{status}</h5>
            </div>
          );
        },
      },

      {
        accessor: "maxScore",
        Header: "Max Score",
        Cell: ({ value }) => {
          return (
            <div className="d-lg-flex align-items-center">
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h5>{value !== undefined ? value : "--"}</h5>
              </div>
            </div>
          );
        },
      },
      {
        accessor: "userScore",
        Header: "Reader Score",
        Cell: ({ value }) => {
          return (
            <div className="d-lg-flex align-items-center">
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h5>{value !== undefined ? value : "--"}</h5>
              </div>
            </div>
          );
        },
      },
      {
        accessor: "success",
        Header: "Result",
        Cell: ({ value }) => {
          return (
            <div className="d-lg-flex align-items-center">
              <div className="ms-lg-3 mt-2 mt-lg-0">
                {value !== undefined ? (
                  value ? (
                    <h5 className="mb-1 text-success">Success</h5>
                  ) : (
                    <h5 className="mb-1 text-danger">Failed</h5>
                  )
                ) : (
                  <h5>--</h5>
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessor: "percentage",
        Header: "Reader Mark Percent",
        Cell: ({ value }) => {
          return (
            <div className="d-lg-flex align-items-center">
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h5>{value !== undefined ? value + "%" : "--"}</h5>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => results, [results]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          /* 	id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Row>
        <Col className="d-flex justify-content-end">
          <div>
            <Button
              variant="primary"
              style={{ marginBottom: "15px" }}
              onClick={() => handleReader()}
            >
              Back To Readers List
            </Button>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search quiz"
                    />
                  </Col>
                </Row>
              </div>

              <div className="table-responsive ">
                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Result Details:{" "}
                    <span className="text-secondary">{quizTitle}</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {typeof finalResult === "object" && finalResult !== null && (
                    <div>
                      <h4>Max Score: {finalResult?.maxScore}</h4>
                      <h4>Reader Score: {finalResult?.userScore}</h4>
                      <h4>Pass Mark: {finalResult?.pass_mark}%</h4>
                      <h4>Percentage: {finalResult?.percentage} %</h4>
                      {finalResult?.success ? (
                        <h4>
                          Result:{" "}
                          <span style={{ color: "green" }}>Success</span>
                        </h4>
                      ) : (
                        <h4>
                          Result: <span style={{ color: "red" }}>Failure</span>
                        </h4>
                      )}
                      <h4>Questions with Answers:</h4>
                      <ul>
                        {finalResult?.answers?.map((answer, index) => (
                          <li key={index}>
                            <h5> Question {index + 1} :</h5>{" "}
                            {answer?.question?.title}
                            <br />
                            <h5>Answer:</h5>{" "}
                            {answer?.question?.type == "true-false"
                              ? answer.answer
                              : answer?.answer?.content}
                            <span style={{ marginLeft: "10px" }}>
                              {answer.success ? (
                                <Check size={16} color="green" />
                              ) : (
                                <X size={16} color="red" />
                              )}{" "}
                            </span>
                            {/* Add more lines for other properties as needed */}
                          </li>
                        ))}
                      </ul>
                      {/* <h4>Incorrect Questions:</h4>
                      <ul>
                        {finalResult?.incorrect_questions?.map(
                          (question, index) => (
                            <li key={index}>
                              <h5> Question {index + 1} :</h5> {question}
                              <br />
                            </li>
                          )
                        )}
                      </ul> */}
                    </div>
                  )}
                  {/* // ) : finalResult === "incorrected" ? (
                  //   <div>
                  //     {essayQuestions?.map((question) => (
                  //       <div key={question?.answer_token}>
                  //         <h4>
                  //           Question:{" "}
                  //           <span
                  //             style={{ fontSize: "14px", color: "#818EA1" }}
                  //           >
                  //             {question.question_text.content}
                  //           </span>
                  //         </h4>
                  //         <h4>
                  //           Explanation:{" "}
                  //           <span
                  //             style={{ fontSize: "14px", color: "#818EA1" }}
                  //           >
                  //             {question.question_text.explanation}
                  //           </span>
                  //         </h4>
                  //         <h4>
                  //           Reader Answer:{" "}
                  //           <span
                  //             style={{ fontSize: "14px", color: "#818EA1" }}
                  //           >
                  //             {question.answer}
                  //           </span>
                  //         </h4>
                  //         <Button
                  //           style={{ marginBottom: "30px", marginLeft: "50%" }}
                  //           variant="primary"
                  //           disabled={loading}
                  //           onClick={() =>
                  //             navigate(
                  //               `/teacher_dashboard/quiz/essay_question/?token=${question.answer_token}&user_id=${user_id}&session_id=${session_id}`
                  //             )
                  //           }
                  //         >
                  //           Correct This Question
                  //         </Button>
                  //       </div>
                  //     ))}
                  //   </div>
                  // ) : finalResult === "incomplete" ? (
                  //   <div>
                  //     <h3>The reader has not yet passed this quiz.</h3>
                  //   </div>
                  // ) : null} */}
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ReaderQuizs;
