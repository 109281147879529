import { fetchAllUsersAction } from "actions/users";
import React, { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";

const AutocompleteStudents = ({ setUserId }) => {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchAllUsersAction());
  }, [dispatch]);

  const handleSelection = (selected) => {
    if (selected.length > 0) setUserId(selected[0].id);
  };

  return (
    <div className="mb-1">
      <Typeahead
        id="autocomplete-example"
        labelKey="username"
        options={users}
        placeholder="Type to search..."
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompleteStudents;
