import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useEffect, useState, } from 'react';
// import { BrowserRouter as Router, Route,Switch, Link } from 'react-router-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row, Tab, Card, Breadcrumb, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import sub custom components
import SessionsDetailsTable from './SessionDetailsTable';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchTeacherAction } from 'actions/teachers';


const SessionDetailsPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const teacher_id = searchParams.get("teacher_id");
	const [selectedDate, setSelectedDate] = useState(null);
	const handleListTeacher = () => {
		navigate("/books-management/choose_teacher")
	};
	const {teacher}= useSelector(state => state.teachers)
	useEffect(()=>{
        if (teacher_id){
            dispatch(fetchTeacherAction(teacher_id))
        } 
     },[dispatch,teacher_id])
	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold"><span style={{color:"red"}}>{teacher?.username}</span>'s Sessions </h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Sessions</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
					<Col className="d-flex justify-content-start" >
						<div  style={{marginBottom:"-10%"}}>

							<DatePicker
								selected={selectedDate}
								onChange={(date) => setSelectedDate(date)}
								dateFormat="MM/dd/yyyy"
								placeholderText="Filter by Start Date"
								className="form-control"
							/>
						</div>
					</Col>
					<Col className="d-flex justify-content-end">
						<div>

							<Button variant="primary" style={{ marginBottom: "15px" }} onClick={() => handleListTeacher()}>
								Back To List of Teachers
							</Button>
						</div>
					</Col>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
									<SessionsDetailsTable 
									selectedDate={selectedDate} 
									/>
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
			<ToastContainer />
		</Fragment>
	);
};

export default SessionDetailsPage;
