import {
  createBookTextAction,
  editBookTextAction,
  getBookTextAction,
} from "actions/books";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import QuestionModal from "./QuestionModal";

const BookTextModal = ({
  show,
  setShow,
  id,
  setBook_id,
  title,
  setBookTitle,
}) => {
  const dispatch = useDispatch();
  const { bookText } = useSelector((state) => state.books);

  const [text, setText] = useState("");
  const [showQuestions, setShowQuestions] = useState(false);

  const handleCloseQuestions = () => {
    setShowQuestions(false);
  };

  const handleClose = () => {
    setText("");
    setShow(false);
    setBook_id(null);
    setBookTitle("");
  };

  useEffect(() => {
    if (id) {
      dispatch(getBookTextAction(id));
    }
  }, [id]);

  useEffect(() => {
    if (bookText?.id) {
      setText(bookText.text);
    }
  }, [bookText]);

  const handleCreateText = () => {
    if (bookText?.id) {
      dispatch(editBookTextAction(id, { text }, handleClose));
    } else {
      dispatch(createBookTextAction({ book_id: id, text }, handleClose));
    }
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {bookText?.id ? "Update Text" : " Add Text"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Text</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleCreateText()}>
            {bookText?.id ? "Update " : " Create"}
          </Button>
          {bookText?.id && (
            <Button variant="primary" onClick={() => setShowQuestions(true)}>
              Edit Questions
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <QuestionModal
        show={showQuestions}
        handleClose={handleCloseQuestions}
        title={title}
      />
    </Fragment>
  );
};

export default BookTextModal;
