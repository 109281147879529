// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import Checkbox from "components/elements/advance-table/Checkbox";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { SessionSubscriptionData } from "data/dashboard/command-pack/PackSubscriptionData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  ApproveFollowedSessionAction,
  RejectFollowedSessionAction,
  deleteFollowedSessionRequestAction,
  fetchAllFollowedSessionsAction,
} from "actions/followedSession";
import SpinnerLoading from "../SpinnerLoading";

// import data files

const NotApprovedSession = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [sessionTitle, setSessionTitle] = useState("");
  const { followedsessions } = useSelector((state) => state.followedSession);
  useEffect(() => {
    dispatch(fetchAllFollowedSessionsAction());
  }, [dispatch]);

  const handleApproveFollowedSessionRequest = ({
    user_id,
    session_id,
    session_name,
  }) => {
    setFollowedSessionRequestToApproved({ user_id, session_id });
    setSessionTitle(session_name);
    setShowApproveModal(true);
  };
  const confirmApprovedFollowedSessionRequest = (e) => {
    if (FollowedSessionRequestToApproved) {
      dispatch(
        ApproveFollowedSessionAction(
          FollowedSessionRequestToApproved,
          sessionTitle
        )
      );
      setFollowedSessionRequestToApproved(null);
      setShowApproveModal(false);
      e.preventDefault();
    }
  };

  const handleRejectFollowedSessionRequest = ({ user_id, session_id }) => {
    setFollowedSessionRequestToRejected({ user_id, session_id });
    setShowRejectModal(true);
  };
  const confirmRejectedFollowedSessionRequest = () => {
    if (FollowedSessionRequestToRejected) {
      dispatch(RejectFollowedSessionAction(FollowedSessionRequestToRejected));
      setFollowedSessionRequestToRejected(null);
      setShowRejectModal(false);
    }
  };

  const handleDeleteFollowedSessionRequest = ({ user_id, session_id }) => {
    setFollowedSessionRequestToDelete({ user_id, session_id });
    setShowDeleteModal(true);
  };

  const confirmDeleteFollowedSessionRequest = () => {
    if (
      FollowedSessionRequestToDelete &&
      confirmationMessage === "DELETE REQUEST"
    ) {
      dispatch(
        deleteFollowedSessionRequestAction(FollowedSessionRequestToDelete)
      );
      setFollowedSessionRequestToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [
    FollowedSessionRequestToRejected,
    setFollowedSessionRequestToRejected,
  ] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [
    FollowedSessionRequestToApproved,
    setFollowedSessionRequestToApproved,
  ] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [FollowedSessionRequestToDelete, setFollowedSessionRequestToDelete] =
    useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const columns = useMemo(
    () => [
      { accessor: "session_name", Header: "Session Title" },
      // { accessor: 'session_id', Header: 'Session ID' },
      { accessor: "username", Header: "Reader Name" },
      { accessor: "email", Header: "Reader Email" },
      // { accessor: 'user_id', Header: 'Reader ID' },
      {
        id: "status",
        accessor: "approved",
        Header: "STATUS",
        Cell: ({ value }) => {
          return (
            <DotBadge
              bg={value === true ? "success" : value === false ? "warning" : ""}
            ></DotBadge>
          );
        },
      },
      {
        id: "action",
        accessor: "approved",
        Header: "Action",
        Cell: ({ value, row }) => {
          if (value === false) {
            return (
              <Fragment>
                <Button
                  href="#"
                  variant="success"
                  className="btn-sm"
                  onClick={() =>
                    handleApproveFollowedSessionRequest({
                      user_id: row.original.user_id,
                      session_id: row.original.session_id,
                      session_name: row.original.session_name,
                    })
                  }
                >
                  Approved
                </Button>{" "}
                {userData?.role == "admin" && (
                  <Button
                    href="#"
                    variant="danger"
                    className="btn-sm"
                    onClick={() =>
                      handleDeleteFollowedSessionRequest({
                        user_id: row.original.user_id,
                        session_id: row.original.session_id,
                      })
                    }
                  >
                    Delete
                  </Button>
                )}
              </Fragment>
            );
          }
          if (value === true) {
            return (
              <Button
                href="#"
                variant="secondary"
                className="btn-sm"
                disabled={loading}
                onClick={() =>
                  handleRejectFollowedSessionRequest({
                    user_id: row.original.user_id,
                    session_id: row.original.session_id,
                  })
                }
              >
                Change Status
              </Button>
            );
          }
        },
      },
      /* 			{
                            accessor: 'action',
                            Header: 'Action',
                            Cell: () => {
                                return <ActionMenu />;
                            }
                        } */
    ],
    []
  );

  const data = useMemo(
    () => followedsessions.filter((session) => session.approved === false),
    [followedsessions]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Session"
            />
          </Col>
        </Row>
      </div>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="table-responsive ">
          <Table {...getTableProps()} className="text-nowrap">
            <thead className="table-light">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this request ?</p>
          <h4 className="text-secondary">
            you must write "DELETE REQUEST" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant={
              confirmationMessage !== "DELETE REQUEST" ? "secondary" : "danger"
            }
            disabled={confirmationMessage !== "DELETE REQUEST"}
            onClick={confirmDeleteFollowedSessionRequest}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApproveModal} onHide={() => setShowApproveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Approve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to approve this request ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowApproveModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={(e) => confirmApprovedFollowedSessionRequest(e)}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Change this status ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={confirmRejectedFollowedSessionRequest}
          >
            Change Status
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default NotApprovedSession;
