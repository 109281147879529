import React, { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTeachersAction } from "actions/teachers";
import { fetchAllPacksAction } from "actions/packs";

const AutocompleteTeachers = ({
  selectedTeacher,
  setSelectedTeacher,
  session,
}) => {
  const dispatch = useDispatch();

  const { teachers } = useSelector((state) => state.teachers);
  const teacher = teachers.filter((e) => e.id === session.teacher_id);

  useEffect(() => {
    if (teacher) setSelectedTeacher(teacher);
  }, [session.teacher_id]);
  useEffect(() => {
    dispatch(fetchAllTeachersAction());
  }, [dispatch]);

  const handleSelection = (selected) => {
    setSelectedTeacher(selected);
  };
  
  const options = [...teachers.map((item) => item)];

  return (
    <div className="mb-1">
      <Typeahead
        id="autocomplete-example"
        labelKey="username"
        options={options}
        placeholder="Type to search..."
        selected={selectedTeacher}
        onChange={handleSelection}
      />
    </div>
  );
};
const AutocompletePacks = ({ selectedPack, setSelectedPack, session }) => {
  const dispatch = useDispatch();
  const { packs } = useSelector((state) => state.packs);
  const pack = packs ? packs?.filter((e) => e.id === session.pack_id) : [];

  useEffect(() => {
    if (pack) setSelectedPack(pack);
  }, [session.pack_id]);
  useEffect(() => {
    dispatch(fetchAllPacksAction());
  }, [dispatch]);

  const handlePackSelection = (selected) => {
    setSelectedPack(selected);
  };

  const options = [...packs.map((item) => item)];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="title"
        options={options}
        placeholder="Type to search..."
        selected={selectedPack}
        onChange={handlePackSelection}
      />
    </div>
  );
};

export { AutocompleteTeachers, AutocompletePacks };
