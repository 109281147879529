import {
  createNotification,
  createNotificationCat,
  createNotificationUser,
  deleteNotification,
  deleteNotificationCat,
  deleteNotificationUser,
  fetchNotifications,
  fetchNotificationsCat,
  fetchNotificationsForUser,
  fetchOneNotification,
  fetchOneNotificationCat,
  fetchUsersInPack,
  updateNotification,
  updateNotificationCat,
} from "api";
import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_CATEGORY,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_CATEGORY,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_CATEGORY,
  FETCH_ONE_NOTIFICATION_CATEGORY,
  GET_USER_NOTIFICATION,
  LOADING,
  NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_CATEGORY,
} from "./constants";

export const fetchAllNotiCategoriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchNotificationsCat();

    dispatch({
      type: FETCH_NOTIFICATION_CATEGORY,
      payload: data.categorys,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createNotiCategoryAction =
  (cat, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createNotificationCat(cat);
      handleClose();
      dispatch({ type: CREATE_NOTIFICATION_CATEGORY, payload: data });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const deleteNotiCategoryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    await deleteNotificationCat(id);

    dispatch({ type: DELETE_NOTIFICATION_CATEGORY, payload: id });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getNotiCategoryAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await fetchOneNotificationCat(id);

    dispatch({ type: FETCH_ONE_NOTIFICATION_CATEGORY, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const updateNotificationCatAction =
  (cat, id, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateNotificationCat(cat, id);
      handleClose();
      dispatch({ type: UPDATE_NOTIFICATION_CATEGORY, payload: data });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const fetchAllNotificationAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchNotifications(1);
    dispatch({
      type: FETCH_NOTIFICATION,
      payload: data.data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createNotificationAction =
  (cat, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
 
      const { data } = await createNotification(cat);
      if (data.cat.title === "User") {
        await createNotificationUser({
          user_id: data.target,
          notification_id: data._id,
        });
      }

      dispatch({ type: CREATE_NOTIFICATION, payload: data });
      dispatch({ type: LOADING, payload: false });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const deleteNotificationAction = (id, all) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    await deleteNotification(id);

    if (!all) {
      await deleteNotificationUser(id);
    }

    dispatch({ type: DELETE_NOTIFICATION, payload: id });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const updateNotificationAction =
  (noti, id, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateNotification(noti, id);

      dispatch({ type: UPDATE_NOTIFICATION, payload: data });
      dispatch({ type: LOADING, payload: false });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const createNotificationForPackAction =
  (noti, pack_id, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data: users } = await fetchUsersInPack(pack_id);

      if (users.users.length > 0) {
        await Promise.all(
          users.users.map(async (e) => {
            const { data } = await createNotification({
              ...noti,
              target: e.user_id,
            });
            await createNotificationUser({
              user_id: data.target,
              notification_id: data._id,
            });
          })
        );
      }

      // dispatch({ type: CREATE_NOTIFICATION, payload: data });
      dispatch({ type: LOADING, payload: false });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

  export const fetchUserNotificationAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await fetchNotificationsForUser(id);
      
      if (data.notifications.length > 0) {
        const notiData = await Promise.all(
          data.notifications.map(async (e) => {
            try {
            const { data: noti } = await fetchOneNotification(e.notification_id);
  
            if (noti) {
              return noti;
            }
          } catch (error){
            console.log(error);
          }
          })
        );
        dispatch({
          type: GET_USER_NOTIFICATION,
          payload: notiData.reverse(),
        });
      }
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
    }
  };

  export const newNotificationAction = (noti) => async (dispatch) => {
    try {
      dispatch({ type: NEW_NOTIFICATION, payload: noti });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
    }
  };
