import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import node module libraries
import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Col,
  Row,
  Tab,
  Card,
  Nav,
  Breadcrumb,
  Button,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PackTable from "./PackTable";
import AddBookToPack from "./AddBookToPack";
import {
  fetchPackAction,
  fetchBooksInPackAction,
  generateCodeAction,
  fetchCodesAction,
  updateCodeAction,
  generateTemplateAction,
} from "actions/packs";
import { Copy } from "react-feather";
import Follower from "./Follower";

const PackDetails = () => {
  const { loading, user } = useSelector((state) => state.auth);

  const [codeNumber, setCodeNumber] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pack, books, codes } = useSelector((state) => state.packs);
  useEffect(() => {
    if (id) dispatch(fetchPackAction(id));
    dispatch(fetchBooksInPackAction(id));
  }, [dispatch, id]);

  const packId = pack.id;
  const handleGenerateCode = (codeNumber, packId, handleClose) => {
    dispatch(generateCodeAction(codeNumber, packId, handleClose));
  };

  const [showModal, setShowModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [copiedCodeId, setCopiedCodeId] = useState(null);

  const handleCopyCode = (codeId, code) => {
    setCopiedCodeId(codeId);
    if (codeId !== null) {
      dispatch(updateCodeAction(codeId));
    }
  };
  const handleClose = () => {
    setShowCodeModal(false);
    setCodeNumber(0);
  };
  const handleFetchCodes = (id) => {
    dispatch(fetchCodesAction(id));
    setShowViewModal(true);
  };

  const handleGenerateTemplate = () => {
    dispatch(generateTemplateAction(id));
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h3 className="mb-1  fw-bold">
                {" "}
                Pack Title :{" "}
                <span size="10px" className="text-secondary">
                  {pack?.title}
                </span>{" "}
              </h3>
            </div>
          </div>
          <div>
            <h4 className="mb-1  fw-bold">
              Level :
              <span size="10px" className="text-secondary">
                {" "}
                {pack?.level}
              </span>
            </h4>
          </div>
          <div>
            <h4 className="mb-1  fw-bold">
              Age :
              <span size="10px" className="text-secondary">
                {" "}
                {pack?.age}
              </span>
            </h4>
          </div>
          <div>
            <h4 className="mb-1 fw-bold">
              Book Number :
              <span size="10px" className="text-secondary">
                {" "}
                {pack?.book_number}
              </span>
            </h4>
          </div>
          <div>
            <h4
              className="mb-1 fw-bold"
              style={{ color: pack?.code < 20 ? "red" : "inherit" }}
            >
              Intellect Code Number :<span>{pack?.code}</span>
            </h4>
          </div>
        </Col>
        {user?.school == "IRead" && (
          <Row style={{ paddingTop: "30px" }}>
            <Col className="d-flex justify-content-start mb-4">
              <div>
                <Button
                  disabled={loading}
                  onClick={() => setShowCodeModal(true)}
                >
                  Generate Code
                </Button>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <Button
                  variant="secondary"
                  disabled={loading}
                  onClick={() => handleFetchCodes(id)}
                >
                  Show Codes
                </Button>
              </div>
            </Col>
            <Col className="d-flex justify-content-end mb-4">
              <AddBookToPack
                showModal={showModal}
                setShowModal={setShowModal}
              />

              <Button
                disabled={loading}
                style={{ marginLeft: "10px" }}
                onClick={() => handleGenerateTemplate()}
              >
                Generate Template
              </Button>
            </Col>
          </Row>
        )}
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="books">
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="books" className="mb-sm-3 mb-md-0">
                      Books
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="users" className="mb-sm-3 mb-md-0">
                      follower
                    </Nav.Link>
                  </Nav.Item>

                  {/* <Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											List of User
										</Nav.Link>
									</Nav.Item> */}
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="books" className="pb-4">
                    <PackTable books={books} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="users" className="pb-4">
                    <Follower />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <Modal show={showCodeModal} onHide={() => setShowCodeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Iread Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Please input the desired number of code:</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setCodeNumber(Number(e.target.value))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCodeModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleGenerateCode(codeNumber, packId, handleClose)}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Codes in This pack</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          <h5>Click the copy button to access and copy the code.</h5>
          <Form.Group>
            {codes?.map((codeObj) => (
              <div
                key={codeObj.id}
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "20px", color: "black" }}>
                    {" "}
                    Code:{" "}
                  </span>
                  <span
                    style={{
                      filter:
                        copiedCodeId === codeObj.id ? "blur(0)" : "blur(3px)",
                    }}
                  >
                    {codeObj.code}
                  </span>
                  <CopyToClipboard
                    text={codeObj.code}
                    onCopy={() => handleCopyCode(codeObj.id)}
                  >
                    <Button
                      variant="outline-primary"
                      size="sm"
                      style={{ marginLeft: "80%", marginTop: "-40px" }}
                    >
                      <Copy size="15px" className="dropdown-item-icon" />
                    </Button>
                  </CopyToClipboard>
                </p>
              </div>
            ))}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowViewModal(false)}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => handleSaveChange()}>
						Save
					</Button> */}
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Fragment>
  );
};

export default PackDetails;
