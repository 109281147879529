import { GET_ALL_TEMPLATES, GET_ONE_TEMPLATE } from "actions/constants";


  
  const templatesReducer = (
    state = { templates: [], template: {} },
    action
  ) => {
    switch (action.type) {
      case GET_ALL_TEMPLATES:
        return { ...state, templates: action?.payload };
      case GET_ONE_TEMPLATE:
        return { ...state, template: action?.payload };
    
      default:
        return state;
    }
  };
  
  export default templatesReducer;
  