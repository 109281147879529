import { Button, Form, Offcanvas } from "react-bootstrap";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import AutocompleteStudents from "./AutocompleteStudents";

const AddUserToSession = ({
  showOffcanvas,
  setShowOffcanvas,
  setUserId,
  handleAddStudentToSession,
}) => {
  const { loading } = useSelector((state) => state.auth);

  const handleClose = () => {
    setUserId(null);
    setShowOffcanvas(false);
  };
  const handleShow = () => setShowOffcanvas(true);

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add Student
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> Add Student To Session</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Select Student</Form.Label>
              <AutocompleteStudents setUserId={setUserId} />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={() => handleAddStudentToSession()}
              >
                Add Student
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddUserToSession;
