import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';

import { Col, Row, Tab, Card, Nav, Breadcrumb, Button, Modal } from 'react-bootstrap';
// import sub custom components
import SessionsTable from './SessionsTable';
// import data files
import {
	AllSessionsData
} from 'data/courses/AllSessionsData';

import AddNewSession from './AddNewSession';
import { fetchBookAction } from 'actions/books';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const AllSessions = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const book_id = searchParams.get("book_id");
	const { book } = useSelector((state) => state.books);
	const [sessionId, setSessionId] = useState(null)
	const [sessionLength, setSessionLength] = useState("")
	const [showSession, setShowSession] = useState(false);
	 useEffect(() => {
	 	if (sessionId) setShowSession(true)
	 }, [sessionId])

	 useEffect(() => {
		dispatch(fetchBookAction(book_id))
	}, [dispatch])



	// Blank Event Object
	/* 	var date = new Date();
		const blankEvent = {
			title: '',
			start: date.setDate(date.getDate()),
			end: date.setDate(date.getDate() + 1),
			allDay: false,
			url: '',
			extendedProps: {
				category: '',
				location: '',
				description: ''
			}
		}; */

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Session for <span style={{color:"red"}}>{book?.title}</span></h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Book management</Breadcrumb.Item>
								<Breadcrumb.Item active>Session</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						{/* 						<div>
					 <Link to="#" className="btn btn-primary">
								Add New Session
							</Link>	
						</div> */}
						<Col className="d-flex justify-content-end">
							<div>
								{/* <Button
								onClick={() => {
									setIsEditEvent(false);
									setSelectedEvent(blankEvent);
									setShowEventOffcanvas(true);
								}}
							>
								Create new Session
							</Button> */}
								<AddNewSession
								showSession = { showSession }
								setShowSession = { setShowSession }
								sessionLength={sessionLength}
								setSessionId = { setSessionId }
								sessionId = { sessionId }

								/>
							</div>
						</Col>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							{/* <Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
											Approved
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											Pending
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header> */}
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<SessionsTable 
										setSessionId={setSessionId}
										setSessionLength={setSessionLength}
										 />
									</Tab.Pane>
									{/* <Tab.Pane eventKey="approved" className="pb-4">
										<CoursesTable courses_data={allapprovedcourses} />
									</Tab.Pane>
									<Tab.Pane eventKey="pending" className="pb-4">
										<CoursesTable courses_data={allpendingcourses} />
									</Tab.Pane>*/}
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
			<ToastContainer/>
		</Fragment>
	);
};

export default AllSessions;
