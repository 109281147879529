import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { approveUser } from "api";
import {
  APPROVE_USER_SUCCESS,
  LOADING
} from "./constants";

export const approveUserAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

     const data = await approveUser(userId);

    dispatch({ type: APPROVE_USER_SUCCESS, payload: {userId} });
    toast.success(data.data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
