import { CREATE_SHCOOL, DELETE_SHCOOL, GET_ALL_SHCOOLS, GET_ONE_SHCOOL, UPDATE_SHCOOL } from "actions/constants";

  
  const shcoolsReducer = (
    state = { shcools: [], shcool: {} },
    action
  ) => {
    switch (action.type) {
      case GET_ALL_SHCOOLS:
        return { ...state, shcools: action?.payload };
      case GET_ONE_SHCOOL:
        return { ...state, shcool: action?.payload };
      case UPDATE_SHCOOL:
        return { ...state, shcools: state.shcools.map(shcool=>{
            if(shcool.id==action.payload.id){
                return action.payload
            }else{
                return shcool
            }
        })};
      case DELETE_SHCOOL:
        return { ...state, shcools: state.shcools.filter(e=>e.id!=action.payload) };
      case CREATE_SHCOOL:
        return { ...state, shcools:[action.payload,...state.shcools] };
      default:
        return state;
    }
  };
  
  export default shcoolsReducer;
  