import {
  GET_ALL_PACKS,
  GET_PACK,
  EDIT_PACK,
  DELETE_PACK,
  CREATE_PACK,
  GET_BOOKS_IN_PACK,
  DELETE_BOOKS_FROM_PACK,
  ADD_BOOKS_IN_PACK,
  GET_CODES,
} from "../actions/constants";

const packsReducer = (state = { packs:[], pack: {}, books: [], codes:[] }, action) => {
  switch (action.type) {
    case GET_ALL_PACKS:
      return { ...state, packs: action?.payload.packs };
    case GET_PACK:
      return { ...state, pack: action?.payload };
    case EDIT_PACK:
      return { ...state, packs: [...state.packs.filter(e => e.id !== action.payload.id), action.payload] };
    case DELETE_PACK: 
          return {  ...state, packs: state.packs.filter(e => e.id !== action.payload) }; 
    case CREATE_PACK: 
      return {...state, packs: [...state.packs, action.payload]}; 
      case GET_BOOKS_IN_PACK:
        return { ...state, books: action?.payload.books_in_pack };
        case ADD_BOOKS_IN_PACK: 
      return {...state, books: [...state.books, action.payload]}; 
      case DELETE_BOOKS_FROM_PACK: 
      return {  ...state, books: state.books.filter(e => e.id !== action.payload) }; 
      case GET_CODES:
        return { ...state, codes: action?.payload };
    default:
      return state;
  }
};

export default packsReducer;
