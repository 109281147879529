import { Button, Form, Offcanvas } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css"; // Import the date picker styles
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import {
  createNotificationAction,
  fetchAllNotiCategoriesAction,
} from "actions/notification";

import { Typeahead } from "react-bootstrap-typeahead";
import { fetchAllUsersAction } from "actions/users";
import { fetchAllPacksAction } from "actions/packs";

const AddNotification = ({
  showOffcanvas,
  setShowOffcanvas,
  notiId,
  setNotiId,
}) => {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [newNotification, setNewNotification] = useState({
    title: "",
    desc: "",
    link: "",
    cat: "",
    target: "",
  });
  // select user
  const { users } = useSelector((state) => state.users);
  const { packs } = useSelector((state) => state.packs);
  useEffect(() => {
    dispatch(fetchAllUsersAction());
    dispatch(fetchAllPacksAction());
  }, [dispatch]);
  const options = [...users.map((item) => item)];
  const handleSelection = (selected) => {
    if (selected.length > 0)
      setNewNotification({ ...newNotification, target: selected[0].id });
  };
  const handleSelectionPack = (selected) => {
    if (selected.length > 0)
      setNewNotification({ ...newNotification, target: selected[0].id });
  };
  // select cat
  const { categories } = useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(fetchAllNotiCategoriesAction());
  }, [dispatch]);
  const catOptions = [...categories.map((item) => item)];
  const handleSelectionCat = (selected) => {
    if (selected.length > 0) {
      setCategory(selected[0].title);

      if (selected[0].title == "All") {
        setNewNotification({
          ...newNotification,
          cat: selected[0]._id,
          target: "All",
        });
      } else {
        setNewNotification({
          ...newNotification,
          cat: selected[0]._id,
          target: "",
        });
      }
    }
  };

  const handleClose = () => {
    setNewNotification({
      title: "",
      desc: "",
      link: "",
      cat: "",
      target: "",
    });
    setShowOffcanvas(false);
    setNotiId(null);
  };

  const handleShow = () => setShowOffcanvas(true);

  const handleAddNoti = async (e) => {
    dispatch(createNotificationAction(newNotification, handleClose));

    e.preventDefault();
  };

  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add New Notification
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {notiId ? "Update Notification" : "Add New Notification"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Typeahead
                  id="autocomplete-example"
                  labelKey="title"
                  options={catOptions}
                  placeholder="Type to search..."
                  // selected={selectedTeacher}
                  onChange={handleSelectionCat}
                />
              </Form.Group>
              <Form.Label>Notification Titile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Notification Titile"
                id="title"
                required
                value={newNotification.title}
                onChange={(e) =>
                  setNewNotification({
                    ...newNotification,
                    title: e.target.value,
                  })
                }
              />
            </Form.Group>
            {category != "All" && category != "Pack" && (
              <Form.Group className="mb-3">
                <Form.Label>User</Form.Label>
                <Typeahead
                  id="autocomplete-example"
                  labelKey="username"
                  options={options}
                  placeholder="Type to search..."
                  // selected={selectedTeacher}
                  onChange={handleSelection}
                />
              </Form.Group>
            )}
            {category != "All" && category != "User" && (
              <Form.Group className="mb-3">
                <Form.Label>Pack</Form.Label>
                <Typeahead
                  id="autocomplete-example"
                  labelKey="title"
                  options={packs}
                  placeholder="Type to search..."
                  // selected={selectedTeacher}
                  onChange={handleSelectionPack}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Notification Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Notification Description"
                id="desc"
                required
                value={newNotification.desc}
                onChange={(e) =>
                  setNewNotification({
                    ...newNotification,
                    desc: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Notification Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Notification Link"
                id="username"
                required
                value={newNotification.link}
                onChange={(e) =>
                  setNewNotification({
                    ...newNotification,
                    link: e.target.value,
                  })
                }
              />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddNoti(e)}
              >
                {notiId ? "Update Notification" : "Add New Notification"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddNotification;
