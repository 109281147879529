// import node module libraries
import { Fragment, useState, useRef, useEffect } from 'react';
import { Col, Row, Breadcrumb, Card, Button } from 'react-bootstrap';

// import full calendar and it's plugins
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// import sub components
// import AddEditEvent from './AddEditEvent';

// import required data files
import { EventsData } from 'data/dashboard/calendar/EventsData';
import EventDetails from './ShowDetails';
import { useSelector } from 'react-redux';
import { fetchAllSessionsAction } from 'actions/sessions';
import { useDispatch } from 'react-redux';
import { removeDuplicates } from 'helper/utils';
import { Eye, Square } from 'react-feather';
import { SquareFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const CalendarTeacher = () => {
    // Define required states
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
    const [isEditEvent, setIsEditEvent] = useState(false);
    const [calendarApi, setCalendarApi] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState();
    const { sessions } = useSelector(state => state.sessions);
    useEffect(() => {
        dispatch(fetchAllSessionsAction())
    }, [dispatch])
    const TeachersData = sessions.map(e => {
        return {
            teacher_name: e.teacher_name,
            teacher_color: e.teacher_color
        }
    })
    const TeachersColor = removeDuplicates(TeachersData)
    // Methods / Functions
    const handleCloseEventOffcanvas = () => setShowEventOffcanvas(false);

    // Calendar Refs
    const calendarRef = useRef(null);

    // useEffect hook to check calendarApi Update
    useEffect(() => {
        if (calendarApi === null) {
            setCalendarApi(calendarRef.current.getApi());
        }
    }, [calendarApi]);

    // Blank Event Object
    var date = new Date();
    const blankEvent = {
        active: "",
        book_id: 0,
        capacity: 0,
        description: "",
        end: "",
        id: 0,
        location: "",
        name: "",
        pack_id: 0,
        start: "",
        teacher_color: "",
        teacher_id: 0,
        teacher_name: "0"
    };
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const sessionData = sessions.map(e => {
        return {
            id: e.id,
            url: '',
            title: e.name,
            teacherRef:e.teacher_id,
            sessionRef:e.id,
            allDay: true,
            start: new Date(e.start_date),
            end: new Date(e.end_date),
            color: e.teacher_color,
            Teacher: e.teacher_name,
            Enter:new Date(e.start_date),
            Out:new Date(e.end_date)
        }

    })
    // Calendar Options or Properties
    const calendarOptions = {
        ref: calendarRef,
        events: sessionData,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        editable: true,
        eventResizableFromStart: true,
        dayMaxEvents: 2,
        navLinks: true,
        eventClick({ event: clickedEvent }) {
            setIsEditEvent(true);
            setShowEventOffcanvas(true);
            setSelectedEvent(clickedEvent);
        },
        dateClick(info) {
            const ev = blankEvent;
            var date = new Date(info.date);
            date.setDate(date.getDate() + 1);
            ev.start = info.date;
            ev.end = date;
            setSelectedEvent(ev);
            setIsEditEvent(false);
            setShowEventOffcanvas(true);
        },
        eventMouseEnter({ event: calendarEvent, el }) {
            const enterTime = new Date(calendarEvent.extendedProps.Enter);
            const exitTime = new Date(calendarEvent.extendedProps.Out);
        
            enterTime.setHours(enterTime.getHours() - 1);
            exitTime.setHours(exitTime.getHours() - 1);
            const startTime = enterTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const endTime = exitTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const sessionId = calendarEvent.extendedProps.sessionRef;
            const teacherId = calendarEvent.extendedProps.teacherRef;
            const tooltip = document.createElement('div');
            const detailsButton = document.createElement('button');
            detailsButton.innerText = '+';
            detailsButton.className = 'btn btn-primary btn-sm';
            detailsButton.style.marginTop = '30px';
            detailsButton.style.marginLeft = '15px';
            detailsButton.addEventListener('click', () => {
                navigate(`/books-management/sessions_details/session?session_id=${sessionId}&teacher_id=${teacherId}`);
            });
            tooltip.className = 'event-tooltip';
            tooltip.style.backgroundColor = '#fff';
            tooltip.style.color = 'black';
            tooltip.style.border = '1px solid #ccc';
            tooltip.style.padding = '5px';
            tooltip.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.1)';
            tooltip.innerHTML = `
        Teacher: ${calendarEvent.extendedProps.Teacher}<br>
        Start Time: ${startTime}<br>
        End Time: ${endTime}
    `
    tooltip.appendChild(detailsButton);
    document.body.appendChild(tooltip); // Append to the body element

    // Optionally, you can set the position based on mouse coordinates
    const rect = el.getBoundingClientRect();
    tooltip.style.left = `${rect.left}px`;
    tooltip.style.top = `${rect.bottom}px`;

            el.appendChild(tooltip);
        },
        eventMouseLeave({ el }) {
            el.querySelector('.event-tooltip').remove();
        },
        eventClassNames({ event: calendarEvent }) {
            return [`text-white bg-${calendarEvent.extendedProps.category}`];
        }
    };

    return (
        <Fragment>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                        <div className="mb-3 mb-md-0">
                            <h1 className="mb-1 h2 fw-bold">All Sessions</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Calendar of Sessions</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div>
                            {TeachersColor?.map((e, key) =>
                                <div key={key}>
                                    <h5 >
                                        <span style={{ color: e.teacher_color }}><SquareFill /></span> {e.teacher_name}
                                    </h5>
                                </div>
                            )}
                            {/* <Button
								onClick={() => {
									setIsEditEvent(false);
									setSelectedEvent(blankEvent);
									setShowEventOffcanvas(true);
								}}
							>
								Create new Events
							</Button> */}

                            {/* <EventDetails
                                show={showEventOffcanvas}
                                setShowEventOffcanvas={setShowEventOffcanvas}
                                onHide={handleCloseEventOffcanvas}
                                calendarApi={calendarApi}
                                isEditEvent={isEditEvent}
                                selectedEvent={selectedEvent}
                            /> */}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12} xs={12}>
                    <Card>
                        {/* Calendar */}
                        <FullCalendar {...calendarOptions} />
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default CalendarTeacher;
