import {
    GET_ALL_ASSISTANTS,
    GET_ASSISTANT,
    EDIT_ASSISTANT,
    DELETE_ASSISTANT,
    CREATE_ASSISTANT,
  } from "../actions/constants";
  
  const assistantsReducer = (state = { assistants: [], assistant: {} }, action) => {
    switch (action.type) {
      case GET_ALL_ASSISTANTS:
        return { ...state, assistants: action?.payload };
      case GET_ASSISTANT:
        return { ...state, assistant: action?.payload };
      case EDIT_ASSISTANT:
        return { ...state, assistants: [...state.assistants.filter(e => e.id !== action.payload.id), action.payload]};
        
      case DELETE_ASSISTANT:
        return { ...state, assistants: state.assistants.filter(e => e.id !== action.payload) };
      case CREATE_ASSISTANT:
        return { ...state, assistants: [...state.assistants, action.payload] };
      default:
        return state;
    }
  };
  
  export default assistantsReducer;
  